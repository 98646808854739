
export class RoiLocationConstants {
  static readonly skull: string = "SKULL"
  static readonly mandible: string = "MANDIBLE"
  static readonly ribs: string = "RIBS"
  static readonly cervical_spine: string = "CERVICAL_SPINE"
  static readonly thoracic_spine: string = "THORACIC_SPINE"
  static readonly lumbar_spine: string = "LUMBAR_SPINE"
  static readonly sacrum: string = "SACRUM"
  static readonly right_shoulder: string = "RIGHT_SHOULDER"
  static readonly left_shoulder: string = "LEFT_SHOULDER"
  static readonly sternum: string = "STERNUM"
  static readonly right_humerus: string = "RIGHT_HUMERUS"
  static readonly left_humerus: string = "LEFT_HUMERUS"
  static readonly right_radius_ulna: string = "RIGHT_RADIUS_ULNA"
  static readonly left_radius_ulna: string = "LEFT_RADIUS_ULNA"
  static readonly right_hand: string = "RIGHT_HAND"
  static readonly left_hand: string = "LEFT_HAND"
  static readonly ilium: string = "ILIUM"
  static readonly pubis: string = "PUBIS"
  static readonly ischium: string = "ISCHIUM"
  static readonly right_femur: string = "RIGHT_FEMUR"
  static readonly left_femur: string = "LEFT_FEMUR"
  static readonly right_tibia_fibula: string = "RIGHT_TIBIA_FIBULA"
  static readonly left_tibia_fibula: string = "LEFT_TIBIA_FIBULA"
  static readonly right_foot: string = "RIGHT_FOOT"
  static readonly left_foot: string = "LEFT_FOOT"
  static readonly right_patella: string = "RIGHT_PATELLA"
  static readonly left_patella: string = "LEFT_PATELLA"
  static readonly liver: string = "LIVER"
  static readonly spleen: string = "SPLEEN"
  static readonly lungs: string = "LUNGS"
  static readonly thyroid: string = "THYROID"
  static readonly kidneys: string = "KIDNEYS"
  static readonly pancreas: string = "PANCREAS"
  static readonly bladder: string = "BLADDER"
  static readonly aorta: string = "AORTA"
  static readonly adrenals: string = "ADRENALS"
  static readonly bowel: string = "BOWEL"
  static readonly stomach: string = "STOMACH"
  static readonly heart: string = "HEART"
  static readonly eyes: string = "EYES"
  static readonly salivary_glands: string = "SALIVARY_GLANDS"
  static readonly pituitary_gland: string = "PITUITARY_GLAND"
  static readonly choroid_plexus: string = "CHOROID_PLEXUS"
  static readonly brain: string = "BRAIN"
  static readonly extremities: string = "EXTREMITIES"
  static readonly skin: string = "SKIN"
  static readonly trunk: string = "TRUNK"
  static readonly head_neck: string = "HEAD_NECK"
  static readonly chest: string = "CHEST"
  static readonly abdominal: string = "ABDOMINAL"
  static readonly pelvic: string = "PELVIC"
  static readonly legs: string = "LEGS"
  static readonly arms: string = "ARMS"
  static readonly axial_skeleton: string = "AXIAL_SKELETON"
  static readonly appendicular_skeleton: string = "APPENDICULAR_SKELETON"
}

export function getRoiLocationsForReporting(): Array<string> {
  return [
    RoiLocationConstants.head_neck,
    RoiLocationConstants.chest,
    RoiLocationConstants.abdominal,
    RoiLocationConstants.pelvic,
    RoiLocationConstants.arms,
    RoiLocationConstants.legs,
    RoiLocationConstants.skull,
    RoiLocationConstants.mandible,
    RoiLocationConstants.ribs,
    RoiLocationConstants.cervical_spine,
    RoiLocationConstants.thoracic_spine,
    RoiLocationConstants.lumbar_spine,
    RoiLocationConstants.sacrum,
    RoiLocationConstants.right_shoulder,
    RoiLocationConstants.left_shoulder,
    RoiLocationConstants.sternum,
    RoiLocationConstants.right_humerus,
    RoiLocationConstants.left_humerus,
    RoiLocationConstants.right_radius_ulna,
    RoiLocationConstants.left_radius_ulna,
    RoiLocationConstants.right_hand,
    RoiLocationConstants.left_hand,
    RoiLocationConstants.ilium,
    RoiLocationConstants.pubis,
    RoiLocationConstants.ischium,
    RoiLocationConstants.right_femur,
    RoiLocationConstants.left_femur,
    RoiLocationConstants.right_tibia_fibula,
    RoiLocationConstants.left_tibia_fibula,
    RoiLocationConstants.right_foot,
    RoiLocationConstants.left_foot,
    RoiLocationConstants.right_patella,
    RoiLocationConstants.left_patella,
    RoiLocationConstants.liver,
    RoiLocationConstants.spleen,
    RoiLocationConstants.lungs,
    RoiLocationConstants.thyroid,
    RoiLocationConstants.pancreas,
    RoiLocationConstants.adrenals,
    RoiLocationConstants.skin,
  ];
}
