import * as React from "react";
import { Typography, Grid, Paper, Divider, TextField, Button, Box } from "@mui/material";
import WarningIcon from "@mui/icons-material/Warning";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import PublishedWithChangesIcon from "@mui/icons-material/PublishedWithChanges";
import { Form, FormikProvider } from "formik";
import {
  InterscanApplicationTaskTemplate,
  InterscanApplicationTaskTemplateApplicationStepTemplate,
  SingleScanApplicationTaskTemplateApplicationStepTemplate,
  ScanAnalysisStepArtifactViewModel,
  SingleScanApplicationTaskTemplate,
} from "../../app/api/aiq-api";
import { ClientSideBaseAnalysisStepViewModel } from "../../redux/services/view-models/ClientSideBaseAnalysisStepViewModel";
import { ClientSideSingleScanAnalysisViewModel } from "../../redux/services/view-models/ClientSideSingleScanAnalysisViewModel";
import { ClientSideInterscanAnalysisViewModel } from "../../redux/services/view-models/ClientSideInterscanAnalysisViewModel";
import { getFormattedDateFromString } from "../../app/utilities/dateUtilities";
import * as Yup from "yup";
import moment from "moment";
import { AiqConfigurations } from "../../redux/aiqConfigurations";
import { useFormik } from "formik";

import { DragDrop, StatusBar, useUppy } from "@uppy/react";
import Uppy from "@uppy/core";
import XHRUpload1 from "@uppy/xhr-upload";
import StatusBar1 from "@uppy/status-bar";
import { useSelector } from "react-redux";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import "@uppy/status-bar/dist/style.css";
import "@uppy/informer/dist/style.css";
import { enhancedApi } from "../../app/api/enhancedApi";

interface IArtifactSideloadPopupProps {
  onClose: (result: string, success: boolean) => void;
  artifact: ScanAnalysisStepArtifactViewModel;
  step: ClientSideBaseAnalysisStepViewModel<
    InterscanApplicationTaskTemplate | SingleScanApplicationTaskTemplate
  >;
  scanAnalysis: ClientSideSingleScanAnalysisViewModel | ClientSideInterscanAnalysisViewModel;
}

const getFileSuffix = (fileName: string): string => {
  return fileName.split(".").pop()!;
};

/**
 * This is a connected component that displays a list of Platform Studies
 */
export const ArtifactSideloadPopup: React.FunctionComponent<IArtifactSideloadPopupProps> = (props) => {
  const token: string | null = useSelector((state: ApplicationState) => state.auth.token);
  const [uploading, setUploading] = React.useState<boolean>(false);
  const [uploadComplete, setUploadComplete] = React.useState<boolean>(false);
  const [errorText, setError] = React.useState<string | undefined>(undefined);
  const [reasonText, setReasonText] = React.useState<string | undefined>(undefined);
  const [subsequentStep] = React.useState<
    | InterscanApplicationTaskTemplateApplicationStepTemplate
    | SingleScanApplicationTaskTemplateApplicationStepTemplate
    | undefined
  >(props.scanAnalysis.applicationTemplate.steps![props.step.stepSequence! + 1]);
  const [doTransition, transitionResult] = enhancedApi.endpoints.postApiScanAnalysisStepsByIdTransition.useMutation();

  const uppy = useUppy(() => {
    return new Uppy({
      id: "uppy",
      restrictions: {
        maxFileSize: 200000000,
        minFileSize: null,
        maxNumberOfFiles: 1,
        minNumberOfFiles: 1,
        allowedFileTypes: [`.${getFileSuffix(props.artifact.fileName!)}`],
      },
      autoProceed: true,
    })
      .use(StatusBar1)
      .use(XHRUpload1, {
        endpoint: `${AiqConfigurations.baseUrl}/api/ScanProcessingTasks/${props.artifact.taskId}/SingleStepOutputArtifactFile?outputSystemId=${props.artifact.systemId}`,
        formData: true,
        fieldName: "formFile",
        headers: {
          authorization: `Bearer ${token}`,
        },
      })
      .on("upload", () => {
        setUploading(true);
        setError(undefined);
      })
      .on("error", (error) => {
        setError(error.message);
      })
      .on("restriction-failed", (file, error: Error) => {
        setUploading(false);
        setError(error.message);
      })
      .on("cancel-all", () => {
        setUploading(false);
      })
      .on("complete", (result) => {
        if (result.failed.length == 0) {
          setUploading(false);
          setUploadComplete(true);
        } else {
          setError(uppy.getState().error);
        }
      });
  });

  const formik = useFormik({
    initialValues: {
      side_load_reason: AiqConfigurations.sideLoadAcceptanceRequired ? `SAF-${moment().local().format("YY")}-`: undefined,
    },
    validateOnBlur: true,
    validationSchema: Yup.object({
      side_load_reason: AiqConfigurations.sideLoadAcceptanceRequired
        ? Yup.string().matches(
            /^(SAF-[2-3][0-9]-[0-9][0-9][0-9][0-9])$/gm,
            "Required format: SAF-YY-#### - e.g. SAF-22-1234"
          )
        : Yup.string(),
    }),
    onSubmit: (values) => { 
      setReasonText(values.side_load_reason);
      uppy.getPlugin('XHRUpload')?.setOptions({
        endpoint: `${AiqConfigurations.baseUrl}/api/ScanProcessingTasks/${props.artifact.taskId}/SingleStepOutputArtifactFile?outputSystemId=${props.artifact.systemId}&reason=Side-load: ${values.side_load_reason}`
      })
    },
  });
  
  const stopAllAndClose = (result: string, success: boolean) => {
    uppy.cancelAll();
    uppy.close();
    props.onClose(result, success);
  };

  const reprocessInvalidatedSteps = () => {
    if (subsequentStep) {
      var transitionRequest = {
        currentStepId: props.step.scanAnalysisStep.id!,
        targetStepSystemId: subsequentStep.systemId!,
      };
      doTransition({
        id: props.step.scanAnalysisStep.id!,
        scanAnalysisStepTransitionRequest: transitionRequest,
      });
      stopAllAndClose(`Side-load complete - reprocessing starting at ${subsequentStep.displayName}`, true);
    } else {
      stopAllAndClose(`Side-load complete`, true);
    }
  };

  const renderDescriptionText = () => {
    if (props.scanAnalysis instanceof ClientSideSingleScanAnalysisViewModel) {
      let singleScanAnalysis = props.scanAnalysis as ClientSideSingleScanAnalysisViewModel;
      return (
        <Grid container spacing={0}>
          <Grid item xs={2} textAlign="right">
            <Typography variant="body2" sx={{ mr: 1 }} gutterBottom>
              Filename:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom>
              {props.artifact.fileName}
            </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <Typography variant="body2" sx={{ mr: 1 }} gutterBottom>
              Step:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom>
              {props.step.stepTemplate.displayName}
            </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <Typography variant="body2" sx={{ mr: 1 }} gutterBottom>
              Patient:
            </Typography>
          </Grid>
          <Grid item xs={10} sx={{ display: "flex" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom>
              {singleScanAnalysis.patient.patientIdentifier}
            </Typography>
            <Typography variant="body2" sx={{ mr: 1, ml: 2 }} gutterBottom>
              Timepoint:
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom>
              {singleScanAnalysis.timepoint.timepointName}
            </Typography>
            <Typography variant="body2" sx={{ mr: 1, ml: 2 }} gutterBottom>
              Exam Date:
            </Typography>
            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom>
              {getFormattedDateFromString(singleScanAnalysis.exam.examDate)}
            </Typography>
          </Grid>
          <Grid item xs={2} textAlign="right">
            <Typography variant="body2" sx={{ mr: 1 }} gutterBottom>
              Study:
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }} gutterBottom>
              {singleScanAnalysis.study.studyName}
            </Typography>
          </Grid>
        </Grid>
      );
    }
    let interscanAnalysis = props.scanAnalysis as ClientSideInterscanAnalysisViewModel;
    return (
      <Grid container>
        <Grid item xs={2} textAlign="right">
          <Typography variant="body2" sx={{ mr: 1 }}>
            Filename:
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {props.artifact.fileName}
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Typography variant="body2" sx={{ mr: 1 }}>
            Step:
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {props.step.stepTemplate.displayName}
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Typography variant="body2" sx={{ mr: 1 }}>
            Patient:
          </Typography>
        </Grid>
        <Grid item xs={10} sx={{ display: "flex" }}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {interscanAnalysis.sourceScanAnalysisGroups[0][0].patient.patientIdentifier}
          </Typography>
          <Typography variant="body2" sx={{ mr: 1, ml: 2 }}>
            Timepoint:
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {interscanAnalysis.sourceScanAnalysisGroups[0][0].timepoint.timepointName} -{" "}
            {interscanAnalysis.sourceScanAnalysisGroups[1][0].timepoint.timepointName}
          </Typography>
          <Typography variant="body2" sx={{ mr: 1, ml: 2 }}>
            Exam Date:
          </Typography>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {getFormattedDateFromString(interscanAnalysis.sourceScanAnalysisGroups[0][0].exam.examDate)} -{" "}
            {getFormattedDateFromString(interscanAnalysis.sourceScanAnalysisGroups[1][0].exam.examDate)}
          </Typography>
        </Grid>
        <Grid item xs={2} textAlign="right">
          <Typography variant="body2" sx={{ mr: 1 }}>
            Study:
          </Typography>
        </Grid>
        <Grid item xs={10}>
          <Typography variant="body2" sx={{ fontWeight: "bold" }}>
            {interscanAnalysis.study.studyName}
          </Typography>
        </Grid>
      </Grid>
    );
  };

  const renderUploadForm = () => {
    return (
      <Grid data-cy="upload_form" item xs={12} textAlign="center" alignItems="center">
          {!uploading && (
            <Box
            sx={{
              borderStyle: "solid",
              borderWidth: 1,
              padding: 2,
              width: "720px",
              height: "300px",
              margin: "auto"
            }}
           >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                  m: 0,
                }}
              >
                <WarningIcon color="error" />
                <Typography variant="subtitle2" color="primary.main" sx={{ m: 1 }}>
                  This action will invalidate all subsequent steps for this Scan Analysis and any related
                  Interscan Analyses
                </Typography>
                <WarningIcon color="error" />
              </Box>
              <DragDrop
                width="100%"
                height="230px"
                note={`Only files of type .${getFileSuffix(props.artifact.fileName!)} can be accepted`}
                // assuming `props.uppy` contains an Uppy instance:
                uppy={uppy}
                locale={{
                  strings: {
                    // Text to show on the droppable area.
                    // `%{browse}` is replaced with a link that opens the system file selection dialog.
                    dropHereOr: `Drop .${getFileSuffix(props.artifact.fileName!)} file here or %{browse}`,
                    // Used as the label for the link that opens the system file selection dialog.
                    browse: "browse",
                  },
                }}
              />
          </Box>
        )}
        <Box sx={{p: 4, width: 720, textAlign: "center", margin: "auto"}}>
          {uploading && (
            <Box sx={{m: 9, mb:3}}>
              <StatusBar uppy={uppy}  />
            </Box>
          )}
          {errorText && (
            <Typography variant="subtitle2" color="error.main" sx={{ m: 1 }}>
              {errorText!}
            </Typography>
          )}
        </Box>
      </Grid>
    );
  };

  const renderReasonForm = () => {
    return (
      <React.Fragment>
        <Grid item xs={AiqConfigurations.sideLoadAcceptanceRequired ? 5 : 8} textAlign="center">
          <Box sx={{display: "flex", alignItems: "start"}}>
            <TextField
              data-cy="side_load_reason"
              name="side_load_reason"
              label={
                AiqConfigurations.sideLoadAcceptanceRequired ? `Side-load Approval Form ID` : "Side-load Reason"
              }
              fullWidth
              autoFocus
              variant="filled"
              value={formik.values.side_load_reason}
              onBlur={formik.handleBlur}
              onChange={formik.handleChange}
              disabled={formik.isSubmitting}
              inputProps={{ maxLength: AiqConfigurations.sideLoadAcceptanceRequired ? 11 : 1000 }}
              required={AiqConfigurations.sideLoadAcceptanceRequired}
              error={formik.touched.side_load_reason && Boolean(formik.errors.side_load_reason)}
              helperText={formik.touched.side_load_reason && formik.errors.side_load_reason}
            />
            
            <Button
              data-cy="side_load_reason_submit"
              type="submit"
              variant="contained"
              sx={{m: 2, mr: 1, height: 45, visibility: formik.isSubmitting ? "hidden" : "visible"}}
              color= {formik.isValid && formik.dirty ? "primary" : "secondary" }
              disabled={!(!AiqConfigurations.sideLoadAcceptanceRequired || (formik.isValid && formik.dirty))}              
            >
              {(!AiqConfigurations.sideLoadAcceptanceRequired && !(formik.isValid && formik.dirty)) ? "Skip" : "Ok"}
            </Button>
            
          </Box>
          {AiqConfigurations.sideLoadAcceptanceRequired && !formik.isSubmitting &&  (
            <Typography sx={{m: 1}}>Enter Side-load Approval Form number: SAF-YY-####</Typography>
          )}
        </Grid>
      </React.Fragment>
    );
  };

  const renderSuccessForm = () => {
    return (
      <Grid data-cy="success_form" item xs={12} textAlign="center" alignItems="center" sx={{ height: "290px" }}>
        <Box
          sx={{
            borderStyle: "solid",
            borderWidth: 1,
            padding: 5,
            width: "720px",
            minHeight: "250px",
            margin: "auto",
            textAlign: "left",
          }}
        >
          <Box sx={{ width: "100%", textAlign: "center" }}>
            <DoneOutlineIcon fontSize="large" color="success" />
          </Box>
          <Typography sx={{ m: 1 }}>
              Side-load complete.  Due to the change in data, the scan must be re-processed.
          </Typography>
          {subsequentStep && (
            <React.Fragment>
              <Typography color="primary.main" sx={{ m: 1 }}>
                Re-run all steps starting at <b>{subsequentStep.displayName}</b> to
                reprocess this scan with the side-loaded data?
              </Typography>

              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Button
                  data-cy="reprocess_button"
                  variant="contained"
                  onClick={() => reprocessInvalidatedSteps()}
                  color="primary"
                  sx={{ m: 1, height: 50, width: 270 }}
                >
                  <PublishedWithChangesIcon sx={{ m: 1 }} /> Continue Processing
                </Button>
              </Box>
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <Button
                  data-cy="do_not_reprocess_button"
                  variant="contained"
                  onClick={() => stopAllAndClose("Side-load complete. No reprocessing.", true)}
                  color="secondary"
                  sx={{ m: 1, height: 50, width: 270 }}
                > Side-load additional files
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Box>
      </Grid>
    );
  };

  return (
    <React.Fragment>
      <Paper sx={{ maxWidth: "1000px", padding: "20px" }}>
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant="h4" sx={{ mr: 1, minWidth: "1000px" }} gutterBottom>
              Side-load Processing Artifact
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" sx={{}} gutterBottom>
              {renderDescriptionText()}
            </Typography>
          </Grid>
        </Grid>
        <Divider sx={{ m: 1 }} />

        <Grid container spacing={1} justifyContent="flex-start">
          <Grid item xs={12}>
            <FormikProvider value={formik}>
              <Form onSubmit={formik.handleSubmit}>
                <Grid container spacing={1} justifyContent="center" sx={{height: "400px"}}>
                  {!(uploadComplete || uploading) && renderReasonForm()}
                  {((formik.isSubmitting) && !uploadComplete) && renderUploadForm()}
                  {uploadComplete && renderSuccessForm()}
                </Grid>
              </Form>
            </FormikProvider>
            <Grid item xs={12} textAlign="right">
              <Box
                sx={{
                  height: 50,
                  buttonBox: {
                    display: "flex",
                    flexFlow: "row wrap",
                    "& Button": {
                      marginRight: "10px",
                    },
                  },
                }}
              >
              {!uploadComplete && (
                <Button
                  data-cy="cancelButton"
                  variant="contained"
                  onClick={() => stopAllAndClose("Side-load cancelled", false)}
                  color="secondary"
                >
                  Cancel
                </Button>
              )}
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
function XHRUpload(XHRUpload: any, arg1: { endpoint: string }) {
  throw new Error("Function not implemented.");
}
