import { emptyAiqApi as api } from "./aiq-api-empty";
const injectedRtkApi = api.injectEndpoints({
  endpoints: (build) => ({
    getApiCompoundInterscanAnalysesByIdScanAnalysisViewModels: build.query<
      GetApiCompoundInterscanAnalysesByIdScanAnalysisViewModelsApiResponse,
      GetApiCompoundInterscanAnalysesByIdScanAnalysisViewModelsApiArg
    >({
      query: (queryArg) => ({ url: `/api/CompoundInterscanAnalyses/${queryArg.id}/ScanAnalysisViewModels` }),
    }),
    postApiCompoundInterscanAnalysesCreateAndInitialize: build.mutation<
      PostApiCompoundInterscanAnalysesCreateAndInitializeApiResponse,
      PostApiCompoundInterscanAnalysesCreateAndInitializeApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompoundInterscanAnalyses/CreateAndInitialize`,
        method: "POST",
        body: queryArg.compoundInterscanAnalysisCreateAndInitializeRequest,
      }),
    }),
    postApiCompoundInterscanAnalyses: build.mutation<
      PostApiCompoundInterscanAnalysesApiResponse,
      PostApiCompoundInterscanAnalysesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompoundInterscanAnalyses`,
        method: "POST",
        body: queryArg.compoundInterscanAnalysis,
      }),
    }),
    getApiCompoundInterscanAnalyses: build.query<
      GetApiCompoundInterscanAnalysesApiResponse,
      GetApiCompoundInterscanAnalysesApiArg
    >({
      query: () => ({ url: `/api/CompoundInterscanAnalyses` }),
    }),
    putApiCompoundInterscanAnalysesById: build.mutation<
      PutApiCompoundInterscanAnalysesByIdApiResponse,
      PutApiCompoundInterscanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/CompoundInterscanAnalyses/${queryArg.id}`,
        method: "PUT",
        body: queryArg.compoundInterscanAnalysis,
      }),
    }),
    deleteApiCompoundInterscanAnalysesById: build.mutation<
      DeleteApiCompoundInterscanAnalysesByIdApiResponse,
      DeleteApiCompoundInterscanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/CompoundInterscanAnalyses/${queryArg.id}`, method: "DELETE" }),
    }),
    getApiCompoundInterscanAnalysesById: build.query<
      GetApiCompoundInterscanAnalysesByIdApiResponse,
      GetApiCompoundInterscanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/CompoundInterscanAnalyses/${queryArg.id}` }),
    }),
    getApiCompoundInterscanAnalysisViewModelsById: build.query<
      GetApiCompoundInterscanAnalysisViewModelsByIdApiResponse,
      GetApiCompoundInterscanAnalysisViewModelsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/CompoundInterscanAnalysisViewModels/${queryArg.id}` }),
    }),
    getApiCompoundInterscanApplications: build.query<
      GetApiCompoundInterscanApplicationsApiResponse,
      GetApiCompoundInterscanApplicationsApiArg
    >({
      query: () => ({ url: `/api/compoundInterscanApplications` }),
    }),
    getApiConfiguration: build.query<GetApiConfigurationApiResponse, GetApiConfigurationApiArg>({
      query: () => ({ url: `/api/configuration` }),
    }),
    getApiConfigurationBuildInfo: build.query<
      GetApiConfigurationBuildInfoApiResponse,
      GetApiConfigurationBuildInfoApiArg
    >({
      query: () => ({ url: `/api/configuration/BuildInfo` }),
    }),
    postApiExamsByIdScans: build.mutation<PostApiExamsByIdScansApiResponse, PostApiExamsByIdScansApiArg>({
      query: (queryArg) => ({ url: `/api/Exams/${queryArg.id}/scans`, method: "POST", body: queryArg.scan }),
    }),
    getApiExamsByIdScans: build.query<GetApiExamsByIdScansApiResponse, GetApiExamsByIdScansApiArg>({
      query: (queryArg) => ({
        url: `/api/Exams/${queryArg.id}/scans`,
        params: { modalityId: queryArg.modalityId },
      }),
    }),
    postApiExams: build.mutation<PostApiExamsApiResponse, PostApiExamsApiArg>({
      query: (queryArg) => ({ url: `/api/Exams`, method: "POST", body: queryArg.exam }),
    }),
    getApiExams: build.query<GetApiExamsApiResponse, GetApiExamsApiArg>({
      query: () => ({ url: `/api/Exams` }),
    }),
    putApiExamsById: build.mutation<PutApiExamsByIdApiResponse, PutApiExamsByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Exams/${queryArg.id}`, method: "PUT", body: queryArg.exam }),
    }),
    deleteApiExamsById: build.mutation<DeleteApiExamsByIdApiResponse, DeleteApiExamsByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Exams/${queryArg.id}`, method: "DELETE" }),
    }),
    getApiExamsById: build.query<GetApiExamsByIdApiResponse, GetApiExamsByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Exams/${queryArg.id}` }),
    }),
    getApiIdsPatients: build.query<GetApiIdsPatientsApiResponse, GetApiIdsPatientsApiArg>({
      query: (queryArg) => ({
        url: `/api/ids/patients`,
        params: { identifier: queryArg.identifier, studyId: queryArg.studyId },
      }),
    }),
    getApiIdsStudy: build.query<GetApiIdsStudyApiResponse, GetApiIdsStudyApiArg>({
      query: (queryArg) => ({ url: `/api/ids/study`, params: { name: queryArg.name } }),
    }),
    getApiIdsTimepoints: build.query<GetApiIdsTimepointsApiResponse, GetApiIdsTimepointsApiArg>({
      query: (queryArg) => ({
        url: `/api/ids/timepoints`,
        params: { name: queryArg.name, studyId: queryArg.studyId },
      }),
    }),
    postApiInterscanAnalysesCreateAndInitializeUngrouped: build.mutation<
      PostApiInterscanAnalysesCreateAndInitializeUngroupedApiResponse,
      PostApiInterscanAnalysesCreateAndInitializeUngroupedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/InterscanAnalyses/CreateAndInitializeUngrouped`,
        method: "POST",
        body: queryArg.ungroupedInterscanAnalysisCreateAndInitializeRequest,
      }),
    }),
    postApiInterscanAnalysesCreateAndInitializeGrouped: build.mutation<
      PostApiInterscanAnalysesCreateAndInitializeGroupedApiResponse,
      PostApiInterscanAnalysesCreateAndInitializeGroupedApiArg
    >({
      query: (queryArg) => ({
        url: `/api/InterscanAnalyses/CreateAndInitializeGrouped`,
        method: "POST",
        body: queryArg.groupedInterscanAnalysisCreateAndInitializeRequest,
      }),
    }),
    getApiInterscanAnalysesByIdScanAnalysisViewModels: build.query<
      GetApiInterscanAnalysesByIdScanAnalysisViewModelsApiResponse,
      GetApiInterscanAnalysesByIdScanAnalysisViewModelsApiArg
    >({
      query: (queryArg) => ({ url: `/api/InterscanAnalyses/${queryArg.id}/ScanAnalysisViewModels` }),
    }),
    postApiInterscanAnalyses: build.mutation<
      PostApiInterscanAnalysesApiResponse,
      PostApiInterscanAnalysesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/InterscanAnalyses`,
        method: "POST",
        body: queryArg.interscanAnalysis,
      }),
    }),
    getApiInterscanAnalyses: build.query<GetApiInterscanAnalysesApiResponse, GetApiInterscanAnalysesApiArg>({
      query: () => ({ url: `/api/InterscanAnalyses` }),
    }),
    putApiInterscanAnalysesById: build.mutation<
      PutApiInterscanAnalysesByIdApiResponse,
      PutApiInterscanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/InterscanAnalyses/${queryArg.id}`,
        method: "PUT",
        body: queryArg.interscanAnalysis,
      }),
    }),
    deleteApiInterscanAnalysesById: build.mutation<
      DeleteApiInterscanAnalysesByIdApiResponse,
      DeleteApiInterscanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/InterscanAnalyses/${queryArg.id}`, method: "DELETE" }),
    }),
    getApiInterscanAnalysesById: build.query<
      GetApiInterscanAnalysesByIdApiResponse,
      GetApiInterscanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/InterscanAnalyses/${queryArg.id}` }),
    }),
    getApiInterscanAnalysisViewModelsById: build.query<
      GetApiInterscanAnalysisViewModelsByIdApiResponse,
      GetApiInterscanAnalysisViewModelsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/InterscanAnalysisViewModels/${queryArg.id}` }),
    }),
    getApiInterscanApplications: build.query<
      GetApiInterscanApplicationsApiResponse,
      GetApiInterscanApplicationsApiArg
    >({
      query: () => ({ url: `/api/interscanApplications` }),
    }),
    getApiPatientsByIdExams: build.query<GetApiPatientsByIdExamsApiResponse, GetApiPatientsByIdExamsApiArg>({
      query: (queryArg) => ({ url: `/api/Patients/${queryArg.id}/Exams` }),
    }),
    postApiPatients: build.mutation<PostApiPatientsApiResponse, PostApiPatientsApiArg>({
      query: (queryArg) => ({ url: `/api/Patients`, method: "POST", body: queryArg.patient }),
    }),
    getApiPatients: build.query<GetApiPatientsApiResponse, GetApiPatientsApiArg>({
      query: () => ({ url: `/api/Patients` }),
    }),
    putApiPatientsById: build.mutation<PutApiPatientsByIdApiResponse, PutApiPatientsByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Patients/${queryArg.id}`, method: "PUT", body: queryArg.patient }),
    }),
    deleteApiPatientsById: build.mutation<DeleteApiPatientsByIdApiResponse, DeleteApiPatientsByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Patients/${queryArg.id}`, method: "DELETE" }),
    }),
    getApiPatientsById: build.query<GetApiPatientsByIdApiResponse, GetApiPatientsByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Patients/${queryArg.id}` }),
    }),
    getApiPlatformRolesById: build.query<GetApiPlatformRolesByIdApiResponse, GetApiPlatformRolesByIdApiArg>({
      query: (queryArg) => ({ url: `/api/PlatformRoles/${queryArg.id}` }),
    }),
    getApiPlatformRoles: build.query<GetApiPlatformRolesApiResponse, GetApiPlatformRolesApiArg>({
      query: () => ({ url: `/api/PlatformRoles` }),
    }),
    getApiPlatformUsersSelf: build.query<GetApiPlatformUsersSelfApiResponse, GetApiPlatformUsersSelfApiArg>({
      query: () => ({ url: `/api/PlatformUsers/self` }),
    }),
    getApiPlatformUsersByIdRoles: build.query<
      GetApiPlatformUsersByIdRolesApiResponse,
      GetApiPlatformUsersByIdRolesApiArg
    >({
      query: (queryArg) => ({ url: `/api/PlatformUsers/${queryArg.id}/roles` }),
    }),
    postApiPlatformUsersByIdRoles: build.mutation<
      PostApiPlatformUsersByIdRolesApiResponse,
      PostApiPlatformUsersByIdRolesApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PlatformUsers/${queryArg.id}/roles`,
        method: "POST",
        body: queryArg.body,
      }),
    }),
    deleteApiPlatformUsersByIdRolesAndRoleId: build.mutation<
      DeleteApiPlatformUsersByIdRolesAndRoleIdApiResponse,
      DeleteApiPlatformUsersByIdRolesAndRoleIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PlatformUsers/${queryArg.id}/roles/${queryArg.roleId}`,
        method: "DELETE",
      }),
    }),
    postApiPlatformUsers: build.mutation<PostApiPlatformUsersApiResponse, PostApiPlatformUsersApiArg>({
      query: (queryArg) => ({ url: `/api/PlatformUsers`, method: "POST", body: queryArg.platformUser }),
    }),
    getApiPlatformUsers: build.query<GetApiPlatformUsersApiResponse, GetApiPlatformUsersApiArg>({
      query: () => ({ url: `/api/PlatformUsers` }),
    }),
    putApiPlatformUsersById: build.mutation<
      PutApiPlatformUsersByIdApiResponse,
      PutApiPlatformUsersByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/PlatformUsers/${queryArg.id}`,
        method: "PUT",
        body: queryArg.platformUser,
      }),
    }),
    deleteApiPlatformUsersById: build.mutation<
      DeleteApiPlatformUsersByIdApiResponse,
      DeleteApiPlatformUsersByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/PlatformUsers/${queryArg.id}`, method: "DELETE" }),
    }),
    getApiPlatformUsersById: build.query<GetApiPlatformUsersByIdApiResponse, GetApiPlatformUsersByIdApiArg>({
      query: (queryArg) => ({ url: `/api/PlatformUsers/${queryArg.id}` }),
    }),
    postApiResponseAnalysisReportsGenerate: build.mutation<
      PostApiResponseAnalysisReportsGenerateApiResponse,
      PostApiResponseAnalysisReportsGenerateApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ResponseAnalysisReports/generate`,
        method: "POST",
        body: queryArg.responseAnalysisReportCreationRequest,
      }),
    }),
    postApiResponseAnalysisReports: build.mutation<
      PostApiResponseAnalysisReportsApiResponse,
      PostApiResponseAnalysisReportsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ResponseAnalysisReports`,
        method: "POST",
        body: queryArg.responseAnalysisReport,
      }),
    }),
    getApiResponseAnalysisReports: build.query<
      GetApiResponseAnalysisReportsApiResponse,
      GetApiResponseAnalysisReportsApiArg
    >({
      query: () => ({ url: `/api/ResponseAnalysisReports` }),
    }),
    putApiResponseAnalysisReportsById: build.mutation<
      PutApiResponseAnalysisReportsByIdApiResponse,
      PutApiResponseAnalysisReportsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ResponseAnalysisReports/${queryArg.id}`,
        method: "PUT",
        body: queryArg.responseAnalysisReport,
      }),
    }),
    deleteApiResponseAnalysisReportsById: build.mutation<
      DeleteApiResponseAnalysisReportsByIdApiResponse,
      DeleteApiResponseAnalysisReportsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ResponseAnalysisReports/${queryArg.id}`, method: "DELETE" }),
    }),
    getApiResponseAnalysisReportsById: build.query<
      GetApiResponseAnalysisReportsByIdApiResponse,
      GetApiResponseAnalysisReportsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ResponseAnalysisReports/${queryArg.id}` }),
    }),
    getApiScanAnalysesByScans: build.query<
      GetApiScanAnalysesByScansApiResponse,
      GetApiScanAnalysesByScansApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalyses/ByScans`,
        params: { anatomicalScanId: queryArg.anatomicalScanId, functionalScanId: queryArg.functionalScanId },
      }),
    }),
    getApiScanAnalysesByIdSteps: build.query<
      GetApiScanAnalysesByIdStepsApiResponse,
      GetApiScanAnalysesByIdStepsApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanAnalyses/${queryArg.id}/Steps` }),
    }),
    postApiScanAnalyses: build.mutation<PostApiScanAnalysesApiResponse, PostApiScanAnalysesApiArg>({
      query: (queryArg) => ({ url: `/api/ScanAnalyses`, method: "POST", body: queryArg.scanAnalysis }),
    }),
    getApiScanAnalyses: build.query<GetApiScanAnalysesApiResponse, GetApiScanAnalysesApiArg>({
      query: () => ({ url: `/api/ScanAnalyses` }),
    }),
    putApiScanAnalysesById: build.mutation<PutApiScanAnalysesByIdApiResponse, PutApiScanAnalysesByIdApiArg>({
      query: (queryArg) => ({
        url: `/api/ScanAnalyses/${queryArg.id}`,
        method: "PUT",
        body: queryArg.scanAnalysis,
      }),
    }),
    deleteApiScanAnalysesById: build.mutation<
      DeleteApiScanAnalysesByIdApiResponse,
      DeleteApiScanAnalysesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanAnalyses/${queryArg.id}`, method: "DELETE" }),
    }),
    getApiScanAnalysesById: build.query<GetApiScanAnalysesByIdApiResponse, GetApiScanAnalysesByIdApiArg>({
      query: (queryArg) => ({ url: `/api/ScanAnalyses/${queryArg.id}` }),
    }),
    postApiScanAnalysisStepsByIdTasks: build.mutation<
      PostApiScanAnalysisStepsByIdTasksApiResponse,
      PostApiScanAnalysisStepsByIdTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps/${queryArg.id}/Tasks`,
        method: "POST",
        body: queryArg.scanProcessingTask,
      }),
    }),
    getApiScanAnalysisStepsByIdTasks: build.query<
      GetApiScanAnalysisStepsByIdTasksApiResponse,
      GetApiScanAnalysisStepsByIdTasksApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanAnalysisSteps/${queryArg.id}/Tasks` }),
    }),
    postApiScanAnalysisStepsByIdTransition: build.mutation<
      PostApiScanAnalysisStepsByIdTransitionApiResponse,
      PostApiScanAnalysisStepsByIdTransitionApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps/${queryArg.id}/Transition`,
        method: "POST",
        body: queryArg.scanAnalysisStepTransitionRequest,
      }),
    }),
    getApiScanAnalysisStepsByIdArtifactUrls: build.query<
      GetApiScanAnalysisStepsByIdArtifactUrlsApiResponse,
      GetApiScanAnalysisStepsByIdArtifactUrlsApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanAnalysisSteps/${queryArg.id}/ArtifactUrls` }),
    }),
    postApiScanAnalysisStepsByIdManuallyMarkComplete: build.mutation<
      PostApiScanAnalysisStepsByIdManuallyMarkCompleteApiResponse,
      PostApiScanAnalysisStepsByIdManuallyMarkCompleteApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps/${queryArg.id}/ManuallyMarkComplete`,
        method: "POST",
        body: queryArg.scanAnalysisStepManualCompletionRequest,
      }),
    }),
    postApiScanAnalysisSteps: build.mutation<
      PostApiScanAnalysisStepsApiResponse,
      PostApiScanAnalysisStepsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps`,
        method: "POST",
        body: queryArg.scanAnalysisStep,
      }),
    }),
    getApiScanAnalysisSteps: build.query<GetApiScanAnalysisStepsApiResponse, GetApiScanAnalysisStepsApiArg>({
      query: () => ({ url: `/api/ScanAnalysisSteps` }),
    }),
    putApiScanAnalysisStepsById: build.mutation<
      PutApiScanAnalysisStepsByIdApiResponse,
      PutApiScanAnalysisStepsByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanAnalysisSteps/${queryArg.id}`,
        method: "PUT",
        body: queryArg.scanAnalysisStep,
      }),
    }),
    deleteApiScanAnalysisStepsById: build.mutation<
      DeleteApiScanAnalysisStepsByIdApiResponse,
      DeleteApiScanAnalysisStepsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanAnalysisSteps/${queryArg.id}`, method: "DELETE" }),
    }),
    getApiScanAnalysisStepsById: build.query<
      GetApiScanAnalysisStepsByIdApiResponse,
      GetApiScanAnalysisStepsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanAnalysisSteps/${queryArg.id}` }),
    }),
    getApiScanAnalysisViewModelsById: build.query<
      GetApiScanAnalysisViewModelsByIdApiResponse,
      GetApiScanAnalysisViewModelsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanAnalysisViewModels/${queryArg.id}` }),
    }),
    getApiScanModalitiesById: build.query<
      GetApiScanModalitiesByIdApiResponse,
      GetApiScanModalitiesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanModalities/${queryArg.id}` }),
    }),
    getApiScanModalities: build.query<GetApiScanModalitiesApiResponse, GetApiScanModalitiesApiArg>({
      query: () => ({ url: `/api/ScanModalities` }),
    }),
    postApiScanpackagesProcessRequest: build.mutation<
      PostApiScanpackagesProcessRequestApiResponse,
      PostApiScanpackagesProcessRequestApiArg
    >({
      query: (queryArg) => ({
        url: `/api/scanpackages/process_request`,
        method: "POST",
        body: queryArg.scanPackageProcessRequest,
      }),
    }),
    postApiScanpackagesValidation: build.mutation<
      PostApiScanpackagesValidationApiResponse,
      PostApiScanpackagesValidationApiArg
    >({
      query: (queryArg) => ({
        url: `/api/scanpackages/validation`,
        method: "POST",
        body: queryArg.scanPackageProcessRequest,
      }),
    }),
    postApiScanpackagesValidateManifestFile: build.mutation<
      PostApiScanpackagesValidateManifestFileApiResponse,
      PostApiScanpackagesValidateManifestFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/scanpackages/validate_manifest_file`,
        method: "POST",
        body: queryArg.scanPackageManifest,
      }),
    }),
    getApiScanProcessingOutputsTemporaryUriByRelativeFilePath: build.query<
      GetApiScanProcessingOutputsTemporaryUriByRelativeFilePathApiResponse,
      GetApiScanProcessingOutputsTemporaryUriByRelativeFilePathApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingOutputs/temporaryUriByRelativeFilePath`,
        params: { fileRelativePath: queryArg.fileRelativePath },
      }),
    }),
    getApiScanProcessingOutputsJsonContentByRelativePath: build.query<
      GetApiScanProcessingOutputsJsonContentByRelativePathApiResponse,
      GetApiScanProcessingOutputsJsonContentByRelativePathApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingOutputs/JsonContentByRelativePath`,
        params: { fileRelativePath: queryArg.fileRelativePath },
      }),
    }),
    getApiScanProcessingTaskArgumentsById: build.query<
      GetApiScanProcessingTaskArgumentsByIdApiResponse,
      GetApiScanProcessingTaskArgumentsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanProcessingTaskArguments/${queryArg.id}` }),
    }),
    getApiScanProcessingTaskArguments: build.query<
      GetApiScanProcessingTaskArgumentsApiResponse,
      GetApiScanProcessingTaskArgumentsApiArg
    >({
      query: () => ({ url: `/api/ScanProcessingTaskArguments` }),
    }),
    postApiScanProcessingTasksByIdRequestsStart: build.mutation<
      PostApiScanProcessingTasksByIdRequestsStartApiResponse,
      PostApiScanProcessingTasksByIdRequestsStartApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}/Requests/Start`,
        method: "POST",
        body: queryArg.scanProcessingTaskStartRequest,
      }),
    }),
    getApiScanProcessingTasksByIdDto: build.query<
      GetApiScanProcessingTasksByIdDtoApiResponse,
      GetApiScanProcessingTasksByIdDtoApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanProcessingTasks/${queryArg.id}/Dto` }),
    }),
    getApiScanProcessingTasksByIdStatuses: build.query<
      GetApiScanProcessingTasksByIdStatusesApiResponse,
      GetApiScanProcessingTasksByIdStatusesApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanProcessingTasks/${queryArg.id}/statuses` }),
    }),
    postApiScanProcessingTasksByIdSingleStepOutputArtifactFile: build.mutation<
      PostApiScanProcessingTasksByIdSingleStepOutputArtifactFileApiResponse,
      PostApiScanProcessingTasksByIdSingleStepOutputArtifactFileApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}/SingleStepOutputArtifactFile`,
        method: "POST",
        body: queryArg.body,
        params: { outputSystemId: queryArg.outputSystemId, reason: queryArg.reason },
      }),
    }),
    postApiScanProcessingTasksByIdSingleStepOutputArtifactText: build.mutation<
      PostApiScanProcessingTasksByIdSingleStepOutputArtifactTextApiResponse,
      PostApiScanProcessingTasksByIdSingleStepOutputArtifactTextApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}/SingleStepOutputArtifactText`,
        method: "POST",
        body: queryArg.body,
        params: { outputSystemId: queryArg.outputSystemId, reason: queryArg.reason },
      }),
    }),
    getApiScanProcessingTasksByIdSingleScanInputSourceUrl: build.query<
      GetApiScanProcessingTasksByIdSingleScanInputSourceUrlApiResponse,
      GetApiScanProcessingTasksByIdSingleScanInputSourceUrlApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}/SingleScanInputSourceUrl`,
        params: { analysisTaskArgumentKey: queryArg.analysisTaskArgumentKey },
      }),
    }),
    postApiScanProcessingTasks: build.mutation<
      PostApiScanProcessingTasksApiResponse,
      PostApiScanProcessingTasksApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks`,
        method: "POST",
        body: queryArg.scanProcessingTask,
      }),
    }),
    getApiScanProcessingTasks: build.query<
      GetApiScanProcessingTasksApiResponse,
      GetApiScanProcessingTasksApiArg
    >({
      query: () => ({ url: `/api/ScanProcessingTasks` }),
    }),
    putApiScanProcessingTasksById: build.mutation<
      PutApiScanProcessingTasksByIdApiResponse,
      PutApiScanProcessingTasksByIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/ScanProcessingTasks/${queryArg.id}`,
        method: "PUT",
        body: queryArg.scanProcessingTask,
      }),
    }),
    deleteApiScanProcessingTasksById: build.mutation<
      DeleteApiScanProcessingTasksByIdApiResponse,
      DeleteApiScanProcessingTasksByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanProcessingTasks/${queryArg.id}`, method: "DELETE" }),
    }),
    getApiScanProcessingTasksById: build.query<
      GetApiScanProcessingTasksByIdApiResponse,
      GetApiScanProcessingTasksByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanProcessingTasks/${queryArg.id}` }),
    }),
    getApiScanProcessingTaskStatusesById: build.query<
      GetApiScanProcessingTaskStatusesByIdApiResponse,
      GetApiScanProcessingTaskStatusesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanProcessingTaskStatuses/${queryArg.id}` }),
    }),
    getApiScanProcessingTaskStatuses: build.query<
      GetApiScanProcessingTaskStatusesApiResponse,
      GetApiScanProcessingTaskStatusesApiArg
    >({
      query: () => ({ url: `/api/ScanProcessingTaskStatuses` }),
    }),
    getApiScanProcessingTaskStatusTypesById: build.query<
      GetApiScanProcessingTaskStatusTypesByIdApiResponse,
      GetApiScanProcessingTaskStatusTypesByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/ScanProcessingTaskStatusTypes/${queryArg.id}` }),
    }),
    getApiScanProcessingTaskStatusTypes: build.query<
      GetApiScanProcessingTaskStatusTypesApiResponse,
      GetApiScanProcessingTaskStatusTypesApiArg
    >({
      query: () => ({ url: `/api/ScanProcessingTaskStatusTypes` }),
    }),
    getApiScansByIdExam: build.query<GetApiScansByIdExamApiResponse, GetApiScansByIdExamApiArg>({
      query: (queryArg) => ({ url: `/api/Scans/${queryArg.id}/Exam` }),
    }),
    postApiScans: build.mutation<PostApiScansApiResponse, PostApiScansApiArg>({
      query: (queryArg) => ({ url: `/api/Scans`, method: "POST", body: queryArg.scan }),
    }),
    getApiScans: build.query<GetApiScansApiResponse, GetApiScansApiArg>({
      query: () => ({ url: `/api/Scans` }),
    }),
    putApiScansById: build.mutation<PutApiScansByIdApiResponse, PutApiScansByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Scans/${queryArg.id}`, method: "PUT", body: queryArg.scan }),
    }),
    deleteApiScansById: build.mutation<DeleteApiScansByIdApiResponse, DeleteApiScansByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Scans/${queryArg.id}`, method: "DELETE" }),
    }),
    getApiScansById: build.query<GetApiScansByIdApiResponse, GetApiScansByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Scans/${queryArg.id}` }),
    }),
    getApiSexesById: build.query<GetApiSexesByIdApiResponse, GetApiSexesByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Sexes/${queryArg.id}` }),
    }),
    getApiSexes: build.query<GetApiSexesApiResponse, GetApiSexesApiArg>({
      query: () => ({ url: `/api/Sexes` }),
    }),
    getApiSingleScanApplications: build.query<
      GetApiSingleScanApplicationsApiResponse,
      GetApiSingleScanApplicationsApiArg
    >({
      query: () => ({ url: `/api/singleScanApplications` }),
    }),
    getApiStudiesByIdExams: build.query<GetApiStudiesByIdExamsApiResponse, GetApiStudiesByIdExamsApiArg>({
      query: (queryArg) => ({ url: `/api/Studies/${queryArg.id}/Exams` }),
    }),
    postApiStudiesByIdPatientsAndPatientId: build.mutation<
      PostApiStudiesByIdPatientsAndPatientIdApiResponse,
      PostApiStudiesByIdPatientsAndPatientIdApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}/patients/${queryArg.patientId}`,
        method: "POST",
      }),
    }),
    getApiStudiesByIdPatients: build.query<
      GetApiStudiesByIdPatientsApiResponse,
      GetApiStudiesByIdPatientsApiArg
    >({
      query: (queryArg) => ({ url: `/api/Studies/${queryArg.id}/patients` }),
    }),
    getApiStudiesByIdTimepoints: build.query<
      GetApiStudiesByIdTimepointsApiResponse,
      GetApiStudiesByIdTimepointsApiArg
    >({
      query: (queryArg) => ({ url: `/api/Studies/${queryArg.id}/timepoints` }),
    }),
    getApiStudiesByIdScanProcessingTaskStatuses: build.query<
      GetApiStudiesByIdScanProcessingTaskStatusesApiResponse,
      GetApiStudiesByIdScanProcessingTaskStatusesApiArg
    >({
      query: (queryArg) => ({ url: `/api/Studies/${queryArg.id}/scanProcessingTaskStatuses` }),
    }),
    getApiStudiesByIdScanAnalysisViewModels: build.query<
      GetApiStudiesByIdScanAnalysisViewModelsApiResponse,
      GetApiStudiesByIdScanAnalysisViewModelsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}/scanAnalysisViewModels`,
        params: {
          patientIdentifierSearchPattern: queryArg.patientIdentifierSearchPattern,
          pageNumber: queryArg.pageNumber,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    getApiStudiesByIdScanAnalysisViewModelsByIds: build.query<
      GetApiStudiesByIdScanAnalysisViewModelsByIdsApiResponse,
      GetApiStudiesByIdScanAnalysisViewModelsByIdsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}/scanAnalysisViewModelsByIds`,
        params: { scanAnalysisIds: queryArg.scanAnalysisIds },
      }),
    }),
    getApiStudiesByIdInterscanAnalysisViewModels: build.query<
      GetApiStudiesByIdInterscanAnalysisViewModelsApiResponse,
      GetApiStudiesByIdInterscanAnalysisViewModelsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}/interscanAnalysisViewModels`,
        params: {
          patientIdentifierSearchPattern: queryArg.patientIdentifierSearchPattern,
          pageNumber: queryArg.pageNumber,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    getApiStudiesByIdCompoundInterscanAnalysisViewModels: build.query<
      GetApiStudiesByIdCompoundInterscanAnalysisViewModelsApiResponse,
      GetApiStudiesByIdCompoundInterscanAnalysisViewModelsApiArg
    >({
      query: (queryArg) => ({
        url: `/api/Studies/${queryArg.id}/compoundInterscanAnalysisViewModels`,
        params: {
          patientIdentifierSearchPattern: queryArg.patientIdentifierSearchPattern,
          pageNumber: queryArg.pageNumber,
          pageSize: queryArg.pageSize,
        },
      }),
    }),
    postApiStudies: build.mutation<PostApiStudiesApiResponse, PostApiStudiesApiArg>({
      query: (queryArg) => ({ url: `/api/Studies`, method: "POST", body: queryArg.study }),
    }),
    getApiStudies: build.query<GetApiStudiesApiResponse, GetApiStudiesApiArg>({
      query: () => ({ url: `/api/Studies` }),
    }),
    putApiStudiesById: build.mutation<PutApiStudiesByIdApiResponse, PutApiStudiesByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Studies/${queryArg.id}`, method: "PUT", body: queryArg.study }),
    }),
    deleteApiStudiesById: build.mutation<DeleteApiStudiesByIdApiResponse, DeleteApiStudiesByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Studies/${queryArg.id}`, method: "DELETE" }),
    }),
    getApiStudiesById: build.query<GetApiStudiesByIdApiResponse, GetApiStudiesByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Studies/${queryArg.id}` }),
    }),
    getApiStudyStatusesById: build.query<GetApiStudyStatusesByIdApiResponse, GetApiStudyStatusesByIdApiArg>({
      query: (queryArg) => ({ url: `/api/StudyStatuses/${queryArg.id}` }),
    }),
    getApiStudyStatuses: build.query<GetApiStudyStatusesApiResponse, GetApiStudyStatusesApiArg>({
      query: () => ({ url: `/api/StudyStatuses` }),
    }),
    postApiTimepoints: build.mutation<PostApiTimepointsApiResponse, PostApiTimepointsApiArg>({
      query: (queryArg) => ({ url: `/api/Timepoints`, method: "POST", body: queryArg.timepoint }),
    }),
    getApiTimepoints: build.query<GetApiTimepointsApiResponse, GetApiTimepointsApiArg>({
      query: () => ({ url: `/api/Timepoints` }),
    }),
    putApiTimepointsById: build.mutation<PutApiTimepointsByIdApiResponse, PutApiTimepointsByIdApiArg>({
      query: (queryArg) => ({
        url: `/api/Timepoints/${queryArg.id}`,
        method: "PUT",
        body: queryArg.timepoint,
      }),
    }),
    deleteApiTimepointsById: build.mutation<
      DeleteApiTimepointsByIdApiResponse,
      DeleteApiTimepointsByIdApiArg
    >({
      query: (queryArg) => ({ url: `/api/Timepoints/${queryArg.id}`, method: "DELETE" }),
    }),
    getApiTimepointsById: build.query<GetApiTimepointsByIdApiResponse, GetApiTimepointsByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Timepoints/${queryArg.id}` }),
    }),
    getApiTracersById: build.query<GetApiTracersByIdApiResponse, GetApiTracersByIdApiArg>({
      query: (queryArg) => ({ url: `/api/Tracers/${queryArg.id}` }),
    }),
    getApiTracers: build.query<GetApiTracersApiResponse, GetApiTracersApiArg>({
      query: () => ({ url: `/api/Tracers` }),
    }),
  }),
  overrideExisting: false,
});
export { injectedRtkApi as api };
export type GetApiCompoundInterscanAnalysesByIdScanAnalysisViewModelsApiResponse =
  /** status 200 Success */ ScanAnalysisViewModel[];
export type GetApiCompoundInterscanAnalysesByIdScanAnalysisViewModelsApiArg = {
  /** The ID of the Interscan Analysis to get Scan Analysis View models for */
  id: string;
};
export type PostApiCompoundInterscanAnalysesCreateAndInitializeApiResponse =
  /** status 200 Success */ CompoundInterscanAnalysis;
export type PostApiCompoundInterscanAnalysesCreateAndInitializeApiArg = {
  /** The CompoundInterscanAnalysisCreateAndInitializeRequest request object holding parameters for the request */
  compoundInterscanAnalysisCreateAndInitializeRequest: CompoundInterscanAnalysisCreateAndInitializeRequest;
};
export type PostApiCompoundInterscanAnalysesApiResponse = /** status 200 Success */ CompoundInterscanAnalysis;
export type PostApiCompoundInterscanAnalysesApiArg = {
  compoundInterscanAnalysis: CompoundInterscanAnalysis;
};
export type GetApiCompoundInterscanAnalysesApiResponse =
  /** status 200 Success */ CompoundInterscanAnalysis[];
export type GetApiCompoundInterscanAnalysesApiArg = void;
export type PutApiCompoundInterscanAnalysesByIdApiResponse = unknown;
export type PutApiCompoundInterscanAnalysesByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  compoundInterscanAnalysis: CompoundInterscanAnalysis;
};
export type DeleteApiCompoundInterscanAnalysesByIdApiResponse = unknown;
export type DeleteApiCompoundInterscanAnalysesByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiCompoundInterscanAnalysesByIdApiResponse =
  /** status 200 Success */ CompoundInterscanAnalysis;
export type GetApiCompoundInterscanAnalysesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiCompoundInterscanAnalysisViewModelsByIdApiResponse =
  /** status 200 Success */ CompoundInterscanAnalysisViewModel;
export type GetApiCompoundInterscanAnalysisViewModelsByIdApiArg = {
  /** The Id of the Interscan Analysis to get a View Model for */
  id: string;
};
export type GetApiCompoundInterscanApplicationsApiResponse =
  /** status 200 Success */ CompoundInterscanApplication[];
export type GetApiCompoundInterscanApplicationsApiArg = void;
export type GetApiConfigurationApiResponse = unknown;
export type GetApiConfigurationApiArg = void;
export type GetApiConfigurationBuildInfoApiResponse = unknown;
export type GetApiConfigurationBuildInfoApiArg = void;
export type PostApiExamsByIdScansApiResponse = /** status 200 Success */ Scan;
export type PostApiExamsByIdScansApiArg = {
  /** The ID of the Exam to add the Scan to */
  id: string;
  /** The new Scan */
  scan: Scan;
};
export type GetApiExamsByIdScansApiResponse = /** status 200 Success */ Scan[];
export type GetApiExamsByIdScansApiArg = {
  /** The ID of the Exam to get scans for */
  id: string;
  /** (optional) The ID of the ModalityLabel to filter by */
  modalityId?: string;
};
export type PostApiExamsApiResponse = /** status 200 Success */ Exam;
export type PostApiExamsApiArg = {
  exam: Exam;
};
export type GetApiExamsApiResponse = /** status 200 Success */ Exam[];
export type GetApiExamsApiArg = void;
export type PutApiExamsByIdApiResponse = unknown;
export type PutApiExamsByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  exam: Exam;
};
export type DeleteApiExamsByIdApiResponse = unknown;
export type DeleteApiExamsByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiExamsByIdApiResponse = /** status 200 Success */ Exam;
export type GetApiExamsByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiIdsPatientsApiResponse = /** status 200 Success */ string[];
export type GetApiIdsPatientsApiArg = {
  /** The identifier of the Patients to get GUID Ids for */
  identifier?: string;
  /** The ID of the study to narrow results by */
  studyId?: string;
};
export type GetApiIdsStudyApiResponse = /** status 200 Success */ string;
export type GetApiIdsStudyApiArg = {
  /** The name of the Study to get the ID for */
  name?: string;
};
export type GetApiIdsTimepointsApiResponse = /** status 200 Success */ string[];
export type GetApiIdsTimepointsApiArg = {
  /** The name of the Timepoints to get GUID Ids for */
  name?: string;
  /** The ID of the study to narrow results by */
  studyId?: string;
};
export type PostApiInterscanAnalysesCreateAndInitializeUngroupedApiResponse =
  /** status 200 Success */ InterscanAnalysis;
export type PostApiInterscanAnalysesCreateAndInitializeUngroupedApiArg = {
  ungroupedInterscanAnalysisCreateAndInitializeRequest: UngroupedInterscanAnalysisCreateAndInitializeRequest;
};
export type PostApiInterscanAnalysesCreateAndInitializeGroupedApiResponse =
  /** status 200 Success */ InterscanAnalysis;
export type PostApiInterscanAnalysesCreateAndInitializeGroupedApiArg = {
  groupedInterscanAnalysisCreateAndInitializeRequest: GroupedInterscanAnalysisCreateAndInitializeRequest;
};
export type GetApiInterscanAnalysesByIdScanAnalysisViewModelsApiResponse =
  /** status 200 Success */ ScanAnalysisViewModel[];
export type GetApiInterscanAnalysesByIdScanAnalysisViewModelsApiArg = {
  /** The ID of the Interscan Analysis to get Scan Analysis View models for */
  id: string;
};
export type PostApiInterscanAnalysesApiResponse = /** status 200 Success */ InterscanAnalysis;
export type PostApiInterscanAnalysesApiArg = {
  interscanAnalysis: InterscanAnalysis;
};
export type GetApiInterscanAnalysesApiResponse = /** status 200 Success */ InterscanAnalysis[];
export type GetApiInterscanAnalysesApiArg = void;
export type PutApiInterscanAnalysesByIdApiResponse = unknown;
export type PutApiInterscanAnalysesByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  interscanAnalysis: InterscanAnalysis;
};
export type DeleteApiInterscanAnalysesByIdApiResponse = unknown;
export type DeleteApiInterscanAnalysesByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiInterscanAnalysesByIdApiResponse = /** status 200 Success */ InterscanAnalysis;
export type GetApiInterscanAnalysesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiInterscanAnalysisViewModelsByIdApiResponse =
  /** status 200 Success */ InterscanAnalysisViewModel;
export type GetApiInterscanAnalysisViewModelsByIdApiArg = {
  /** The Id of the Interscan Analysis to get a View Model for */
  id: string;
};
export type GetApiInterscanApplicationsApiResponse = /** status 200 Success */ InterscanApplication[];
export type GetApiInterscanApplicationsApiArg = void;
export type GetApiPatientsByIdExamsApiResponse = /** status 200 Success */ Exam[];
export type GetApiPatientsByIdExamsApiArg = {
  /** The ID of the Patient to get Exams for */
  id: string;
};
export type PostApiPatientsApiResponse = /** status 200 Success */ Patient;
export type PostApiPatientsApiArg = {
  patient: Patient;
};
export type GetApiPatientsApiResponse = /** status 200 Success */ Patient[];
export type GetApiPatientsApiArg = void;
export type PutApiPatientsByIdApiResponse = unknown;
export type PutApiPatientsByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  patient: Patient;
};
export type DeleteApiPatientsByIdApiResponse = unknown;
export type DeleteApiPatientsByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiPatientsByIdApiResponse = /** status 200 Success */ Patient;
export type GetApiPatientsByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiPlatformRolesByIdApiResponse = /** status 200 Success */ PlatformRole;
export type GetApiPlatformRolesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiPlatformRolesApiResponse = /** status 200 Success */ PlatformRole[];
export type GetApiPlatformRolesApiArg = void;
export type GetApiPlatformUsersSelfApiResponse = /** status 200 Success */ PlatformUser;
export type GetApiPlatformUsersSelfApiArg = void;
export type GetApiPlatformUsersByIdRolesApiResponse = /** status 200 Success */ PlatformRole[];
export type GetApiPlatformUsersByIdRolesApiArg = {
  /** The ID of the Platform User to get roles for */
  id: string;
};
export type PostApiPlatformUsersByIdRolesApiResponse = unknown;
export type PostApiPlatformUsersByIdRolesApiArg = {
  /** The ID of the Platform User to assign the Platform Role */
  id: string;
  /** The ID of the Platform Role to add */
  body: string;
};
export type DeleteApiPlatformUsersByIdRolesAndRoleIdApiResponse = unknown;
export type DeleteApiPlatformUsersByIdRolesAndRoleIdApiArg = {
  /** The ID of the Platform User to remove the Platform Role assignment from */
  id: string;
  /** The ID of the Platform Role to remove */
  roleId: string;
};
export type PostApiPlatformUsersApiResponse = /** status 200 Success */ PlatformUser;
export type PostApiPlatformUsersApiArg = {
  platformUser: PlatformUser;
};
export type GetApiPlatformUsersApiResponse = /** status 200 Success */ PlatformUser[];
export type GetApiPlatformUsersApiArg = void;
export type PutApiPlatformUsersByIdApiResponse = unknown;
export type PutApiPlatformUsersByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  platformUser: PlatformUser;
};
export type DeleteApiPlatformUsersByIdApiResponse = unknown;
export type DeleteApiPlatformUsersByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiPlatformUsersByIdApiResponse = /** status 200 Success */ PlatformUser;
export type GetApiPlatformUsersByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type PostApiResponseAnalysisReportsGenerateApiResponse =
  /** status 200 Success */ ResponseAnalysisReport;
export type PostApiResponseAnalysisReportsGenerateApiArg = {
  /** A request object containing the ID of the interscan analysis to generate a report for */
  responseAnalysisReportCreationRequest: ResponseAnalysisReportCreationRequest;
};
export type PostApiResponseAnalysisReportsApiResponse = /** status 200 Success */ ResponseAnalysisReport;
export type PostApiResponseAnalysisReportsApiArg = {
  responseAnalysisReport: ResponseAnalysisReport;
};
export type GetApiResponseAnalysisReportsApiResponse = /** status 200 Success */ ResponseAnalysisReport[];
export type GetApiResponseAnalysisReportsApiArg = void;
export type PutApiResponseAnalysisReportsByIdApiResponse = unknown;
export type PutApiResponseAnalysisReportsByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  responseAnalysisReport: ResponseAnalysisReport;
};
export type DeleteApiResponseAnalysisReportsByIdApiResponse = unknown;
export type DeleteApiResponseAnalysisReportsByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiResponseAnalysisReportsByIdApiResponse = /** status 200 Success */ ResponseAnalysisReport;
export type GetApiResponseAnalysisReportsByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanAnalysesByScansApiResponse = /** status 200 Success */ ScanAnalysis;
export type GetApiScanAnalysesByScansApiArg = {
  /** The ID of the Anatomical Scan */
  anatomicalScanId?: string;
  /** (optional) The ID of Functional Scan - nullable */
  functionalScanId?: string;
};
export type GetApiScanAnalysesByIdStepsApiResponse = /** status 200 Success */ ScanAnalysisStep[];
export type GetApiScanAnalysesByIdStepsApiArg = {
  /** The ID of the Scan Analysis to get steps for */
  id: string;
};
export type PostApiScanAnalysesApiResponse = /** status 200 Success */ ScanAnalysis;
export type PostApiScanAnalysesApiArg = {
  scanAnalysis: ScanAnalysis;
};
export type GetApiScanAnalysesApiResponse = /** status 200 Success */ ScanAnalysis[];
export type GetApiScanAnalysesApiArg = void;
export type PutApiScanAnalysesByIdApiResponse = unknown;
export type PutApiScanAnalysesByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  scanAnalysis: ScanAnalysis;
};
export type DeleteApiScanAnalysesByIdApiResponse = unknown;
export type DeleteApiScanAnalysesByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiScanAnalysesByIdApiResponse = /** status 200 Success */ ScanAnalysis;
export type GetApiScanAnalysesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type PostApiScanAnalysisStepsByIdTasksApiResponse = /** status 200 Success */ ScanProcessingTask;
export type PostApiScanAnalysisStepsByIdTasksApiArg = {
  /** The ID of the Job to add the Task to */
  id: string;
  /** The Scan Processing Task to add */
  scanProcessingTask: ScanProcessingTask;
};
export type GetApiScanAnalysisStepsByIdTasksApiResponse = /** status 200 Success */ ScanProcessingTask[];
export type GetApiScanAnalysisStepsByIdTasksApiArg = {
  id: string;
};
export type PostApiScanAnalysisStepsByIdTransitionApiResponse = /** status 200 Success */ ScanAnalysisStep;
export type PostApiScanAnalysisStepsByIdTransitionApiArg = {
  id: string;
  scanAnalysisStepTransitionRequest: ScanAnalysisStepTransitionRequest;
};
export type GetApiScanAnalysisStepsByIdArtifactUrlsApiResponse =
  /** status 200 Success */ ScanAnalysisStepArtifactViewModel[];
export type GetApiScanAnalysisStepsByIdArtifactUrlsApiArg = {
  /** The ID of the Scan Processing Step to get the output URLs from */
  id: string;
};
export type PostApiScanAnalysisStepsByIdManuallyMarkCompleteApiResponse = unknown;
export type PostApiScanAnalysisStepsByIdManuallyMarkCompleteApiArg = {
  id: string;
  scanAnalysisStepManualCompletionRequest: ScanAnalysisStepManualCompletionRequest;
};
export type PostApiScanAnalysisStepsApiResponse = /** status 200 Success */ ScanAnalysisStep;
export type PostApiScanAnalysisStepsApiArg = {
  scanAnalysisStep: ScanAnalysisStep;
};
export type GetApiScanAnalysisStepsApiResponse = /** status 200 Success */ ScanAnalysisStep[];
export type GetApiScanAnalysisStepsApiArg = void;
export type PutApiScanAnalysisStepsByIdApiResponse = unknown;
export type PutApiScanAnalysisStepsByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  scanAnalysisStep: ScanAnalysisStep;
};
export type DeleteApiScanAnalysisStepsByIdApiResponse = unknown;
export type DeleteApiScanAnalysisStepsByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiScanAnalysisStepsByIdApiResponse = /** status 200 Success */ ScanAnalysisStep;
export type GetApiScanAnalysisStepsByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanAnalysisViewModelsByIdApiResponse = /** status 200 Success */ ScanAnalysisViewModel;
export type GetApiScanAnalysisViewModelsByIdApiArg = {
  /** The Id of the Scan Analysis to get a View Model for */
  id: string;
};
export type GetApiScanModalitiesByIdApiResponse = /** status 200 Success */ ScanModality;
export type GetApiScanModalitiesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanModalitiesApiResponse = /** status 200 Success */ ScanModality[];
export type GetApiScanModalitiesApiArg = void;
export type PostApiScanpackagesProcessRequestApiResponse = unknown;
export type PostApiScanpackagesProcessRequestApiArg = {
  /** The request, including the location of the Scan Package folder in a file share in a storage account associated with the
                current environment and an optional ID of a Study to associate the data with. If StudyID is absent, the ID/Name in the
                manifest will be used. */
  scanPackageProcessRequest: ScanPackageProcessRequest;
};
export type PostApiScanpackagesValidationApiResponse = /** status 200 Success */ ScanPackageValidationResult;
export type PostApiScanpackagesValidationApiArg = {
  /** The request, including the location of the Scan Package folder in a file share in a storage account associated with the
                current environment and an optional ID of a Study to associate the data with. If StudyID is absent, the ID/Name in the
                manifest will be used. */
  scanPackageProcessRequest: ScanPackageProcessRequest;
};
export type PostApiScanpackagesValidateManifestFileApiResponse =
  /** status 200 Success */ ScanPackageValidationResult;
export type PostApiScanpackagesValidateManifestFileApiArg = {
  /** The manifest file to validate */
  scanPackageManifest: ScanPackageManifest;
};
export type GetApiScanProcessingOutputsTemporaryUriByRelativeFilePathApiResponse =
  /** status 200 Success */ string;
export type GetApiScanProcessingOutputsTemporaryUriByRelativeFilePathApiArg = {
  fileRelativePath?: string;
};
export type GetApiScanProcessingOutputsJsonContentByRelativePathApiResponse =
  /** status 200 Success */ string;
export type GetApiScanProcessingOutputsJsonContentByRelativePathApiArg = {
  fileRelativePath?: string;
};
export type GetApiScanProcessingTaskArgumentsByIdApiResponse =
  /** status 200 Success */ ScanProcessingTaskArgument;
export type GetApiScanProcessingTaskArgumentsByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanProcessingTaskArgumentsApiResponse =
  /** status 200 Success */ ScanProcessingTaskArgument[];
export type GetApiScanProcessingTaskArgumentsApiArg = void;
export type PostApiScanProcessingTasksByIdRequestsStartApiResponse = unknown;
export type PostApiScanProcessingTasksByIdRequestsStartApiArg = {
  id: string;
  /** The Start Request */
  scanProcessingTaskStartRequest: ScanProcessingTaskStartRequest;
};
export type GetApiScanProcessingTasksByIdDtoApiResponse = /** status 200 Success */ string;
export type GetApiScanProcessingTasksByIdDtoApiArg = {
  /** The ID of the Scan Processing Task to get the DTO */
  id: string;
};
export type GetApiScanProcessingTasksByIdStatusesApiResponse =
  /** status 200 Success */ ScanProcessingTaskStatus[];
export type GetApiScanProcessingTasksByIdStatusesApiArg = {
  /** The ID of the Scan Processing Task to get Statuses for */
  id: string;
};
export type PostApiScanProcessingTasksByIdSingleStepOutputArtifactFileApiResponse =
  /** status 200 Success */ ScanAnalysisStepArtifactViewModel;
export type PostApiScanProcessingTasksByIdSingleStepOutputArtifactFileApiArg = {
  /** The ID of the Scan Processing Task to write the output to */
  id: string;
  /** the System ID of the task Output */
  outputSystemId?: string;
  /** Optional - A string describing the reason this file is being written this way */
  reason?: string;
  body: {
    formFile?: Blob;
  };
};
export type PostApiScanProcessingTasksByIdSingleStepOutputArtifactTextApiResponse =
  /** status 200 Success */ ScanAnalysisStepArtifactViewModel;
export type PostApiScanProcessingTasksByIdSingleStepOutputArtifactTextApiArg = {
  /** The ID of the Scan Processing Task to write the output to */
  id: string;
  /** the System ID of the task Output */
  outputSystemId?: string;
  /** Optional - A string describing the reason this file is being written this way */
  reason?: string;
  body: string;
};
export type GetApiScanProcessingTasksByIdSingleScanInputSourceUrlApiResponse =
  /** status 200 Success */ ScanAnalysisStepArtifactViewModel;
export type GetApiScanProcessingTasksByIdSingleScanInputSourceUrlApiArg = {
  /** The ID of the Scan Processing Step to get the input source URL from */
  id: string;
  /** The analysisTaskArgumentKey identifying the specific input */
  analysisTaskArgumentKey?: string;
};
export type PostApiScanProcessingTasksApiResponse = /** status 200 Success */ ScanProcessingTask;
export type PostApiScanProcessingTasksApiArg = {
  scanProcessingTask: ScanProcessingTask;
};
export type GetApiScanProcessingTasksApiResponse = /** status 200 Success */ ScanProcessingTask[];
export type GetApiScanProcessingTasksApiArg = void;
export type PutApiScanProcessingTasksByIdApiResponse = unknown;
export type PutApiScanProcessingTasksByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  scanProcessingTask: ScanProcessingTask;
};
export type DeleteApiScanProcessingTasksByIdApiResponse = unknown;
export type DeleteApiScanProcessingTasksByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiScanProcessingTasksByIdApiResponse = /** status 200 Success */ ScanProcessingTask;
export type GetApiScanProcessingTasksByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanProcessingTaskStatusesByIdApiResponse =
  /** status 200 Success */ ScanProcessingTaskStatus;
export type GetApiScanProcessingTaskStatusesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanProcessingTaskStatusesApiResponse =
  /** status 200 Success */ ScanProcessingTaskStatus[];
export type GetApiScanProcessingTaskStatusesApiArg = void;
export type GetApiScanProcessingTaskStatusTypesByIdApiResponse =
  /** status 200 Success */ ScanProcessingTaskStatusType;
export type GetApiScanProcessingTaskStatusTypesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiScanProcessingTaskStatusTypesApiResponse =
  /** status 200 Success */ ScanProcessingTaskStatusType[];
export type GetApiScanProcessingTaskStatusTypesApiArg = void;
export type GetApiScansByIdExamApiResponse = /** status 200 Success */ Exam;
export type GetApiScansByIdExamApiArg = {
  /** The ID of the Scan to get the Exam for */
  id: string;
};
export type PostApiScansApiResponse = /** status 200 Success */ Scan;
export type PostApiScansApiArg = {
  scan: Scan;
};
export type GetApiScansApiResponse = /** status 200 Success */ Scan[];
export type GetApiScansApiArg = void;
export type PutApiScansByIdApiResponse = unknown;
export type PutApiScansByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  scan: Scan;
};
export type DeleteApiScansByIdApiResponse = unknown;
export type DeleteApiScansByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiScansByIdApiResponse = /** status 200 Success */ Scan;
export type GetApiScansByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiSexesByIdApiResponse = /** status 200 Success */ Sex;
export type GetApiSexesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiSexesApiResponse = /** status 200 Success */ Sex[];
export type GetApiSexesApiArg = void;
export type GetApiSingleScanApplicationsApiResponse = /** status 200 Success */ SingleScanApplication[];
export type GetApiSingleScanApplicationsApiArg = void;
export type GetApiStudiesByIdExamsApiResponse = /** status 200 Success */ Exam[];
export type GetApiStudiesByIdExamsApiArg = {
  /** The ID of the Study for which to retrieve Exams */
  id: string;
};
export type PostApiStudiesByIdPatientsAndPatientIdApiResponse = unknown;
export type PostApiStudiesByIdPatientsAndPatientIdApiArg = {
  /** The ID of the Study to associate the Patient with */
  id: string;
  /** The ID of the Patient to associate with the Study */
  patientId: string;
};
export type GetApiStudiesByIdPatientsApiResponse = /** status 200 Success */ Patient[];
export type GetApiStudiesByIdPatientsApiArg = {
  /** The ID of the Study to get Patients for */
  id: string;
};
export type GetApiStudiesByIdTimepointsApiResponse = /** status 200 Success */ Timepoint[];
export type GetApiStudiesByIdTimepointsApiArg = {
  /** The ID of the Study to get Timepoints for */
  id: string;
};
export type GetApiStudiesByIdScanProcessingTaskStatusesApiResponse =
  /** status 200 Success */ ScanProcessingTaskStatus[];
export type GetApiStudiesByIdScanProcessingTaskStatusesApiArg = {
  /** The ID of the Study to get all Scan Processing Task Statuses for */
  id: string;
};
export type GetApiStudiesByIdScanAnalysisViewModelsApiResponse =
  /** status 200 Success */ ScanAnalysisViewModelPagedResult;
export type GetApiStudiesByIdScanAnalysisViewModelsApiArg = {
  /** The ID of the Study to get Scan Analysis View Models for */
  id: string;
  /** If provided, results will be filtered to Scan Analyses with Patients whose Identifiers contain this string. Default is null. */
  patientIdentifierSearchPattern?: string;
  /** For pagination support. If provided, results will be skipped to this page. Defaults to null. */
  pageNumber?: number;
  /** For pagination support. The number of records per page to return. Defaults to null. */
  pageSize?: number;
};
export type GetApiStudiesByIdScanAnalysisViewModelsByIdsApiResponse =
  /** status 200 Success */ ScanAnalysisViewModel[];
export type GetApiStudiesByIdScanAnalysisViewModelsByIdsApiArg = {
  /** The ID of the Study to get Scan Analysis View Models for */
  id: string;
  /** If provided, results will be filtered to Scan Analyses with Patients whose Identifiers contain this string. Default is null. */
  scanAnalysisIds?: string;
};
export type GetApiStudiesByIdInterscanAnalysisViewModelsApiResponse =
  /** status 200 Success */ InterscanAnalysisViewModelPagedResult;
export type GetApiStudiesByIdInterscanAnalysisViewModelsApiArg = {
  /** The ID of the Study to get Interscan Analysis View Models for */
  id: string;
  /** If provided, results will be filtered to Interscan Analyses with Patients whose Identifiers contain this string. Default is null. */
  patientIdentifierSearchPattern?: string;
  /** For pagination support. If provided, results will be skipped to this page. Defaults to null. */
  pageNumber?: number;
  /** For pagination support. The number of records per page to return. Defaults to null. */
  pageSize?: number;
};
export type GetApiStudiesByIdCompoundInterscanAnalysisViewModelsApiResponse =
  /** status 200 Success */ CompoundInterscanAnalysisViewModelPagedResult;
export type GetApiStudiesByIdCompoundInterscanAnalysisViewModelsApiArg = {
  /** The ID of the Study to get Compound Interscan Analysis View Models for */
  id: string;
  /** If provided, results will be filtered to Compound Interscan Analyses with Patients whose Identifiers contain this string. Default is null. */
  patientIdentifierSearchPattern?: string;
  /** For pagination support. If provided, results will be skipped to this page. Defaults to null. */
  pageNumber?: number;
  /** For pagination support. The number of records per page to return. Defaults to null. */
  pageSize?: number;
};
export type PostApiStudiesApiResponse = /** status 200 Success */ Study;
export type PostApiStudiesApiArg = {
  study: Study;
};
export type GetApiStudiesApiResponse = /** status 200 Success */ Study[];
export type GetApiStudiesApiArg = void;
export type PutApiStudiesByIdApiResponse = unknown;
export type PutApiStudiesByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  study: Study;
};
export type DeleteApiStudiesByIdApiResponse = unknown;
export type DeleteApiStudiesByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiStudiesByIdApiResponse = /** status 200 Success */ Study;
export type GetApiStudiesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiStudyStatusesByIdApiResponse = /** status 200 Success */ StudyStatus;
export type GetApiStudyStatusesByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiStudyStatusesApiResponse = /** status 200 Success */ StudyStatus[];
export type GetApiStudyStatusesApiArg = void;
export type PostApiTimepointsApiResponse = /** status 200 Success */ Timepoint;
export type PostApiTimepointsApiArg = {
  timepoint: Timepoint;
};
export type GetApiTimepointsApiResponse = /** status 200 Success */ Timepoint[];
export type GetApiTimepointsApiArg = void;
export type PutApiTimepointsByIdApiResponse = unknown;
export type PutApiTimepointsByIdApiArg = {
  /** The id of the resource instance to update */
  id: string;
  /** The resource instance to update */
  timepoint: Timepoint;
};
export type DeleteApiTimepointsByIdApiResponse = unknown;
export type DeleteApiTimepointsByIdApiArg = {
  /** The ID of the resource instance to delete */
  id: string;
};
export type GetApiTimepointsByIdApiResponse = /** status 200 Success */ Timepoint;
export type GetApiTimepointsByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiTracersByIdApiResponse = /** status 200 Success */ Tracer;
export type GetApiTracersByIdApiArg = {
  /** The ID of the resource to retrieve */
  id: string;
};
export type GetApiTracersApiResponse = /** status 200 Success */ Tracer[];
export type GetApiTracersApiArg = void;
export type AnalysisTypes = "ScanAnalysis" | "InterscanAnalysis" | "CompoundInterscanAnalysis";
export type ScanAnalysis = {
  id?: string;
  type?: AnalysisTypes;
  applicationSystemId?: string | null;
  anatomicalScanId?: string;
  examId?: string | null;
  functionalScanId?: string | null;
  initiatedBy?: string;
  initiatedDate?: string | null;
  meshBundleUrl?: string | null;
};
export type ApplicationOutputTemplate = {
  description?: string | null;
  outputPath?: string | null;
  analysisTaskArgumentKey?: string | null;
  systemId?: string | null;
};
export type ApplicationExtraArgumentTemplate = {
  argumentValue?: string | null;
  analysisTaskArgumentKey?: string | null;
};
export type ApplicationInputTemplate = {
  analysisTaskArgumentKey?: string | null;
  sources?: ApplicationOutputTemplate[] | null;
  source?: ApplicationOutputTemplate;
};
export type SingleScanApplicationTaskTemplate = {
  systemId?: string | null;
  description?: string | null;
  queueName?: string | null;
  outputs?: ApplicationOutputTemplate[] | null;
  extraArguments?: ApplicationExtraArgumentTemplate[] | null;
  inputs?: ApplicationInputTemplate[] | null;
};
export type SingleScanApplicationTaskTemplateApplicationStepTemplate = {
  systemId?: string | null;
  displayName?: string | null;
  description?: string | null;
  automated?: boolean;
  stub?: boolean;
  pauseAfter?: boolean;
  tasks?: SingleScanApplicationTaskTemplate[] | null;
};
export type ScanApplicationAttribute = {
  systemId?: string | null;
  value?: string | null;
};
export type SingleScanApplication = {
  schemaVersion?: number;
  applicationVersion?: number;
  deprecated?: boolean;
  disabled?: boolean;
  displaySortIndex?: number | null;
  buildCommitSHA?: string | null;
  buildDateTime?: string | null;
  buildRunNumber?: string | null;
  systemId?: string | null;
  displayName?: string | null;
  relativeScanProcessingPath?: string | null;
  baseScanProcessingPath?: string | null;
  baseAIModelsPath?: string | null;
  description?: string | null;
  steps?: SingleScanApplicationTaskTemplateApplicationStepTemplate[] | null;
  attributes?: ScanApplicationAttribute[] | null;
  baseSourceDataPath?: string | null;
};
export type Patient = {
  id?: string;
  patientIdentifier?: string | null;
  sexId?: string;
  weight?: number | null;
  createdDate?: string;
  updatedDate?: string;
};
export type Exam = {
  id?: string;
  patientId?: string;
  studyId?: string;
  timepointId?: string;
  examDate?: string | null;
  createdDate?: string;
  updatedDate?: string;
};
export type Timepoint = {
  id?: string;
  studyId?: string;
  timepointName?: string | null;
  sequence?: number;
  createdDate?: string;
  updatedDate?: string;
};
export type Study = {
  id?: string;
  studyName?: string | null;
  createdBy?: string;
  principalInvestigator?: string | null;
  startDate?: string | null;
  statusId?: string | null;
  createdDate?: string;
  updatedDate?: string;
};
export type ScanAnalysisStep = {
  id?: string;
  applicationStepSystemId?: string | null;
  analysisId?: string;
  initiatedBy?: string | null;
  initiatedDate?: string | null;
  approved?: boolean | null;
  approvedBy?: string | null;
  rejected?: boolean | null;
  rejectedBy?: string | null;
  rejectedReason?: string | null;
  complete?: boolean | null;
};
export type ScanProcessingTask = {
  id?: string;
  applicationTaskSystemId?: string | null;
  queueName?: string | null;
  scanAnalysisStepId?: string;
};
export type ScanProcessingTaskStatusKind = "UPDATE" | "WARNING" | "ALERT" | "ERROR";
export type ScanProcessingTaskStatus = {
  id?: string;
  scanProcessingTaskId?: string;
  scanProcessingTaskStatusTypeId?: string;
  kind?: ScanProcessingTaskStatusKind;
  error?: string | null;
  result?: string | null;
  percentComplete?: number | null;
  createdDate?: string;
  createdBy?: string | null;
};
export type ScanProcessingTaskStatusType = {
  id?: string;
  displayLabel?: string | null;
  constantStringValue?: string | null;
};
export type ScanProcessingTaskStatusViewModel = {
  scanProcessingTaskStatus?: ScanProcessingTaskStatus;
  scanProcessingTaskStatusType?: ScanProcessingTaskStatusType;
};
export type ScanProcessingTaskViewModel = {
  scanProcessingTask?: ScanProcessingTask;
  orderedScanProcessingTaskStatuses?: ScanProcessingTaskStatusViewModel[] | null;
  isInProgress?: boolean;
  mostRecentStatus?: ScanProcessingTaskStatusViewModel;
  percentComplete?: number;
  isComplete?: boolean;
  isWaiting?: boolean;
  hasErrors?: boolean;
};
export type ScanAnalysisStepViewModel = {
  scanAnalysisStep?: ScanAnalysisStep;
  tasks?: ScanProcessingTaskViewModel[] | null;
  isInProgress?: boolean;
  isComplete?: boolean;
  isWaiting?: boolean;
  hasErrors?: boolean;
  isAutomated?: boolean;
  timeOfLastStatusUpdate?: string | null;
  averagePercentComplete?: number;
  stepSequence?: number;
};
export type Scan = {
  id?: string;
  scanLabel?: string | null;
  scanSeries?: string | null;
  scanModalityId?: string;
  patientId?: string;
  rawDataLocation?: string | null;
  boneVolume?: number | null;
  createdDate?: string;
  updatedDate?: string;
};
export type ScanAnalysisViewModel = {
  scanAnalysis?: ScanAnalysis;
  singleScanApplication?: SingleScanApplication;
  patient?: Patient;
  exam?: Exam;
  timepoint?: Timepoint;
  study?: Study;
  orderedScanAnalysisSteps?: ScanAnalysisStepViewModel[] | null;
  functionalScan?: Scan;
  anatomicalScan?: Scan;
};
export type CompoundInterscanAnalysis = {
  id?: string;
  type?: AnalysisTypes;
  applicationSystemId?: string | null;
  initiatedBy?: string;
  initiatedDate?: string | null;
};
export type CompoundInterscanAnalysisCreateAndInitializeRequest = {
  compoundInterscanApplicationSystemId?: string | null;
  inputInterscanAnalysisIds?: string[] | null;
};
export type InterscanReferenceLocalInputSource = {
  inputInterscanAnalysisIndex?: number;
  sourceOutputSystemId?: string | null;
};
export type InterscanReferenceLocalInput = {
  analysisTaskArgumentKey?: string | null;
  sources?: InterscanReferenceLocalInputSource[] | null;
};
export type InterscanReferenceSingleScanInputSource = {
  inputScanAnalysisIndex?: number;
  inputScanAnalysisGroupIndex?: number | null;
  sourceOutputSystemId?: string | null;
  inputInterscanAnalysisIndex?: number;
};
export type InterscanReferenceSingleScanInput = {
  analysisTaskArgumentKey?: string | null;
  sources?: InterscanReferenceSingleScanInputSource[] | null;
};
export type CompoundInterscanApplicationTaskTemplate = {
  systemId?: string | null;
  description?: string | null;
  queueName?: string | null;
  outputs?: ApplicationOutputTemplate[] | null;
  extraArguments?: ApplicationExtraArgumentTemplate[] | null;
  localInputs?: ApplicationInputTemplate[] | null;
  interscanReferenceLocalInputs?: InterscanReferenceLocalInput[] | null;
  interscanReferenceSingleScanInputs?: InterscanReferenceSingleScanInput[] | null;
};
export type CompoundInterscanApplicationTaskTemplateApplicationStepTemplate = {
  systemId?: string | null;
  displayName?: string | null;
  description?: string | null;
  automated?: boolean;
  stub?: boolean;
  pauseAfter?: boolean;
  tasks?: CompoundInterscanApplicationTaskTemplate[] | null;
};
export type CompoundInterscanApplication = {
  schemaVersion?: number;
  applicationVersion?: number;
  deprecated?: boolean;
  disabled?: boolean;
  displaySortIndex?: number | null;
  buildCommitSHA?: string | null;
  buildDateTime?: string | null;
  buildRunNumber?: string | null;
  systemId?: string | null;
  displayName?: string | null;
  relativeScanProcessingPath?: string | null;
  baseScanProcessingPath?: string | null;
  baseAIModelsPath?: string | null;
  description?: string | null;
  steps?: CompoundInterscanApplicationTaskTemplateApplicationStepTemplate[] | null;
  attributes?: ScanApplicationAttribute[] | null;
  numberOfInputInterscanAnalyses?: number;
  validInterscanApplicationInputs?: string[] | null;
};
export type InterscanAnalysis = {
  id?: string;
  type?: AnalysisTypes;
  applicationSystemId?: string | null;
  initiatedBy?: string;
  initiatedDate?: string | null;
};
export type SingleScanReferenceInputSource = {
  inputScanAnalysisIndex?: number;
  inputScanAnalysisGroupIndex?: number | null;
  sourceOutputSystemId?: string | null;
};
export type SingleScanReferenceInput = {
  sources?: SingleScanReferenceInputSource[] | null;
  source?: SingleScanReferenceInputSource;
  analysisTaskArgumentKey?: string | null;
};
export type InterscanApplicationTaskTemplate = {
  systemId?: string | null;
  description?: string | null;
  queueName?: string | null;
  outputs?: ApplicationOutputTemplate[] | null;
  extraArguments?: ApplicationExtraArgumentTemplate[] | null;
  singleScanReferenceInputs?: SingleScanReferenceInput[] | null;
  localInputs?: ApplicationInputTemplate[] | null;
};
export type InterscanApplicationTaskTemplateApplicationStepTemplate = {
  systemId?: string | null;
  displayName?: string | null;
  description?: string | null;
  automated?: boolean;
  stub?: boolean;
  pauseAfter?: boolean;
  tasks?: InterscanApplicationTaskTemplate[] | null;
};
export type InterscanApplication = {
  schemaVersion?: number;
  applicationVersion?: number;
  deprecated?: boolean;
  disabled?: boolean;
  displaySortIndex?: number | null;
  buildCommitSHA?: string | null;
  buildDateTime?: string | null;
  buildRunNumber?: string | null;
  systemId?: string | null;
  displayName?: string | null;
  relativeScanProcessingPath?: string | null;
  baseScanProcessingPath?: string | null;
  baseAIModelsPath?: string | null;
  description?: string | null;
  steps?: InterscanApplicationTaskTemplateApplicationStepTemplate[] | null;
  attributes?: ScanApplicationAttribute[] | null;
  numberOfInputSingleScanAnalyses?: number;
  validSingleScanApplicationInputs?: string[] | null;
  validInterscanApplicationInputs?: string[] | null;
  singleScanGroupSizes?: number[] | null;
  singleScanGroupDisplayNames?: string[] | null;
};
export type InterscanAnalysisViewModel = {
  interscanAnalysis?: InterscanAnalysis;
  interscanAnalysisApplication?: InterscanApplication;
  sourceScanAnalyses?: ScanAnalysis[][] | null;
  sourceScanAnalysisVMs?: ScanAnalysisViewModel[][] | null;
  orderedScanAnalysisSteps?: ScanAnalysisStepViewModel[] | null;
  study?: Study;
};
export type CompoundInterscanAnalysisViewModel = {
  compoundInterscanAnalysis?: CompoundInterscanAnalysis;
  compoundInterscanAnalysisApplication?: CompoundInterscanApplication;
  sourceInterscanScanAnalysisVMs?: InterscanAnalysisViewModel[] | null;
  orderedScanAnalysisSteps?: ScanAnalysisStepViewModel[] | null;
  study?: Study;
};
export type UngroupedInterscanAnalysisCreateAndInitializeRequest = {
  singleScanAnalysisIds?: string[] | null;
  interscanApplicationSystemId?: string | null;
};
export type GroupedInterscanAnalysisCreateAndInitializeRequest = {
  singleScanAnalysisIdGroups?: string[][] | null;
  interscanApplicationSystemId?: string | null;
};
export type PlatformRole = {
  id?: string;
  platformRoleName?: string | null;
};
export type PlatformUser = {
  id?: string;
  externalIdentityProviderId?: string | null;
  platformUserName?: string | null;
};
export type ResponseAnalysisScanAnalysisViewModel = {
  scanAnalysisId?: string;
  timepointName?: string | null;
  examDate?: string;
  coronalReferenceImageLocation?: string | null;
  sagittalReferenceImageLocation?: string | null;
  anatomicalVolumeImageLocation?: string | null;
  functionalVolumeImageLocation?: string | null;
  roiPolydataBundleLocation?: string | null;
  roiLabelMapVolumeLocation?: string | null;
  anatomicalScanMetadata?: {
    [key: string]: string | null;
  } | null;
  functionalScanMetadata?: {
    [key: string]: string | null;
  } | null;
  metadata?: {
    [key: string]: string | null;
  } | null;
};
export type Roi = {
  anatomySegmentSysName?: string | null;
  roiIndex?: number;
  roiMax?: number | null;
  roiMean?: number | null;
  roiTotal?: number | null;
  roiVolume?: number | null;
  roiStd?: number | null;
  partial?: boolean | null;
  roiCenterCoordinates?: number[] | null;
};
export type RoiMatchClassificationMethod =
  | "NaFSuvTotal"
  | "NaFSuvMean"
  | "NaFSuvMax"
  | "DefaultSuvMax"
  | "DefaultSuvTotal"
  | "CTVolume"
  | "Concordance"
  | "Unclassified";
export type RoiMatchClassification =
  | "Unknown"
  | "New"
  | "Increasing"
  | "Stable"
  | "Decreasing"
  | "Disappeared"
  | "Unclassifiable"
  | "Scan1"
  | "Scan2"
  | "Both";
export type RoiMatchGroupViewModel = {
  preRois?: Roi[] | null;
  postRois?: Roi[] | null;
  classificationMethod?: RoiMatchClassificationMethod;
  classification?: RoiMatchClassification;
};
export type PatientViewModel = {
  patientIdentifier?: string | null;
  sex?: string | null;
  weight?: number | null;
  metadata?: {
    [key: string]: string | null;
  } | null;
};
export type RoiMatchGroupTabularViewModel = {
  classification?: RoiMatchClassification;
  scan1RoiLocation?: string | null;
  scan1RoiReferenceNumbers?: string | null;
  scan2RoiLocation?: string | null;
  scan2RoiReferenceNumbers?: string | null;
  scan1RoiSliceNumbers?: string | null;
  scan2RoiSliceNumbers?: string | null;
  scan1SuvTotal?: number | null;
  scan2SuvTotal?: number | null;
  scan1SuvTotalPercentOfWhole?: number | null;
  scan2SuvTotalPercentOfWhole?: number | null;
  percentChangeSuvTotal?: number | null;
  scan1SuvMean?: number | null;
  scan2SuvMean?: number | null;
  scan1Volume?: number | null;
  scan2Volume?: number | null;
};
export type ChangeAnalysisDerivedReportData = {
  tabularRoiMatchGroups?: RoiMatchGroupTabularViewModel[] | null;
  changeInOverallBurden?: number | null;
  percentChangeInOverallBurden?: number | null;
  scan1WholeBodySuvMeanByVoxel?: number | null;
  scan2WholeBodySuvMeanByVoxel?: number | null;
  scan1WholeBodySuvTotal?: number | null;
  scan2WholeBodySuvTotal?: number | null;
  scan1WholeBodyRoiVolume?: number | null;
  scan2WholeBodyRoiVolume?: number | null;
  changeInNumberOfRois?: number | null;
  numberOfNewRoiMatchGroups?: number | null;
  scan2PercentOfWholeSuvTotalNew?: number | null;
  numberOfIncreasingRoiMatchGroups?: number | null;
  scan2PercentOfWholeSuvTotalIncreasing?: number | null;
  numberOfStableRoiMatchGroups?: number | null;
  scan2PercentOfWholeSuvTotalStable?: number | null;
  numberOfDecreasingRoiMatchGroups?: number | null;
  scan2PercentOfWholeSuvTotalDecreasing?: number | null;
  numberOfDisappearedRoiMatchGroups?: number | null;
  numberOfScan1RoiMatchGroups?: number | null;
  numberOfScan2RoiMatchGroups?: number | null;
  numberOfUnclassifiableRoiMatchGroups?: number | null;
  scan1DisplayAlias?: string | null;
  scan2DisplayAlias?: string | null;
  scan1DisplayAliasAbbreviated?: string | null;
  scan2DisplayAliasAbbreviated?: string | null;
};
export type ResponseAnalysisReport = {
  id?: string;
  interscanAnalysisId?: string;
  studyName?: string | null;
  preScanAnalysis?: ResponseAnalysisScanAnalysisViewModel;
  postScanAnalysis?: ResponseAnalysisScanAnalysisViewModel;
  preScanAnalysisGroup?: ResponseAnalysisScanAnalysisViewModel[] | null;
  postScanAnalysisGroup?: ResponseAnalysisScanAnalysisViewModel[] | null;
  roiMatchGroups?: RoiMatchGroupViewModel[] | null;
  patient?: PatientViewModel;
  summaryText?: string | null;
  coronalResponseMipLocation?: string | null;
  sagittalResponseMipLocation?: string | null;
  roiMatchClassificationMethod?: RoiMatchClassificationMethod;
  schemaVersion?: number;
  revision?: number;
  createdDate?: string;
  deleted?: boolean | null;
  deletedDate?: string | null;
  metadata?: {
    [key: string]: string | null;
  } | null;
  changeAnalysisDerivedReportData?: ChangeAnalysisDerivedReportData;
};
export type ResponseAnalysisReportCreationRequest = {
  interscanAnalysisId?: string;
};
export type ScanAnalysisStepTransitionRequest = {
  currentStepId?: string;
  targetStepSystemId?: string | null;
};
export type ScanAnalysisStepArtifactViewModel = {
  taskId?: string | null;
  systemId?: string | null;
  fileName?: string | null;
  relativePath?: string | null;
  temporaryUri?: string | null;
  uriExpiration?: string | null;
  success?: boolean;
};
export type ScanAnalysisStepManualCompletionRequest = {
  stepId?: string;
};
export type ScanModality = {
  id?: string;
  scanModalityLabel?: string | null;
  scanModalityName?: string | null;
  scanModalitySequence?: number;
};
export type ScanPackageProcessRequest = {
  scanPackageLocation?: string | null;
  studyId?: string | null;
  singleScanApplicationSystemId?: string | null;
  overwriteOriginal?: boolean;
};
export type ScanPackageManifestTimepoint = {
  id?: string | null;
  timepointName?: string | null;
  sequence?: number;
};
export type ScanPackageManifestScan = {
  id?: string | null;
  label?: string | null;
  series?: string | null;
  modalityLabel?: string | null;
  path?: string | null;
  metadata?: {
    [key: string]: string | null;
  } | null;
};
export type ScanPackageManifestExam = {
  id?: string | null;
  timepoint?: ScanPackageManifestTimepoint;
  examDate?: string | null;
  scans?: ScanPackageManifestScan[] | null;
  metadata?: {
    [key: string]: string | null;
  } | null;
};
export type ScanPackageManifestPatient = {
  id?: string | null;
  identifier?: string | null;
  sexCode?: string | null;
  weightKg?: number | null;
  exams?: ScanPackageManifestExam[] | null;
  metadata?: {
    [key: string]: string | null;
  } | null;
};
export type ScanPackageManifest = {
  singleScanApplicationSystemId?: string | null;
  processed?: boolean | null;
  studyId?: string | null;
  studyName?: string | null;
  patients?: ScanPackageManifestPatient[] | null;
  metadata?: {
    [key: string]: string | null;
  } | null;
};
export type ScanPackageValidationResult = {
  newPatients?: Patient[] | null;
  existingPatients?: Patient[] | null;
  newExams?: Exam[] | null;
  existingExams?: Exam[] | null;
  newTimepoints?: Timepoint[] | null;
  existingTimepoints?: Timepoint[] | null;
  newScans?: Scan[] | null;
  existingScans?: Scan[] | null;
  errorMessages?: string[] | null;
  warningMessages?: string[] | null;
  success?: boolean;
  originalManifest?: ScanPackageManifest;
  validatedManifest?: ScanPackageManifest;
};
export type ScanProcessingTaskArgumentType = "Input" | "Output" | "Extra" | "Unknown";
export type ScanProcessingTaskArgument = {
  id?: string;
  scanProcessingTaskId?: string;
  argumentName?: string | null;
  argumentValue?: string | null;
  argumentType?: ScanProcessingTaskArgumentType;
};
export type ScanProcessingTaskStartRequest = {
  scanProcessingTaskId?: string;
};
export type Sex = {
  id?: string;
  sexCode?: string | null;
  sexLabel?: string | null;
};
export type ScanAnalysisViewModelPagedResult = {
  result?: ScanAnalysisViewModel[] | null;
  pageNumber?: number | null;
  pageSize?: number | null;
  totalCount?: number;
};
export type InterscanAnalysisViewModelPagedResult = {
  result?: InterscanAnalysisViewModel[] | null;
  pageNumber?: number | null;
  pageSize?: number | null;
  totalCount?: number;
};
export type CompoundInterscanAnalysisViewModelPagedResult = {
  result?: CompoundInterscanAnalysisViewModel[] | null;
  pageNumber?: number | null;
  pageSize?: number | null;
  totalCount?: number;
};
export type StudyStatus = {
  id?: string;
  studyStatusLabel?: string | null;
  studyStatusSequence?: number;
};
export type Tracer = {
  id?: string;
  tracerLabel?: string | null;
  tracerName?: string | null;
  tracerSequence?: number;
};
export const {
  useGetApiCompoundInterscanAnalysesByIdScanAnalysisViewModelsQuery,
  usePostApiCompoundInterscanAnalysesCreateAndInitializeMutation,
  usePostApiCompoundInterscanAnalysesMutation,
  useGetApiCompoundInterscanAnalysesQuery,
  usePutApiCompoundInterscanAnalysesByIdMutation,
  useDeleteApiCompoundInterscanAnalysesByIdMutation,
  useGetApiCompoundInterscanAnalysesByIdQuery,
  useGetApiCompoundInterscanAnalysisViewModelsByIdQuery,
  useGetApiCompoundInterscanApplicationsQuery,
  useGetApiConfigurationQuery,
  useGetApiConfigurationBuildInfoQuery,
  usePostApiExamsByIdScansMutation,
  useGetApiExamsByIdScansQuery,
  usePostApiExamsMutation,
  useGetApiExamsQuery,
  usePutApiExamsByIdMutation,
  useDeleteApiExamsByIdMutation,
  useGetApiExamsByIdQuery,
  useGetApiIdsPatientsQuery,
  useGetApiIdsStudyQuery,
  useGetApiIdsTimepointsQuery,
  usePostApiInterscanAnalysesCreateAndInitializeUngroupedMutation,
  usePostApiInterscanAnalysesCreateAndInitializeGroupedMutation,
  useGetApiInterscanAnalysesByIdScanAnalysisViewModelsQuery,
  usePostApiInterscanAnalysesMutation,
  useGetApiInterscanAnalysesQuery,
  usePutApiInterscanAnalysesByIdMutation,
  useDeleteApiInterscanAnalysesByIdMutation,
  useGetApiInterscanAnalysesByIdQuery,
  useGetApiInterscanAnalysisViewModelsByIdQuery,
  useGetApiInterscanApplicationsQuery,
  useGetApiPatientsByIdExamsQuery,
  usePostApiPatientsMutation,
  useGetApiPatientsQuery,
  usePutApiPatientsByIdMutation,
  useDeleteApiPatientsByIdMutation,
  useGetApiPatientsByIdQuery,
  useGetApiPlatformRolesByIdQuery,
  useGetApiPlatformRolesQuery,
  useGetApiPlatformUsersSelfQuery,
  useGetApiPlatformUsersByIdRolesQuery,
  usePostApiPlatformUsersByIdRolesMutation,
  useDeleteApiPlatformUsersByIdRolesAndRoleIdMutation,
  usePostApiPlatformUsersMutation,
  useGetApiPlatformUsersQuery,
  usePutApiPlatformUsersByIdMutation,
  useDeleteApiPlatformUsersByIdMutation,
  useGetApiPlatformUsersByIdQuery,
  usePostApiResponseAnalysisReportsGenerateMutation,
  usePostApiResponseAnalysisReportsMutation,
  useGetApiResponseAnalysisReportsQuery,
  usePutApiResponseAnalysisReportsByIdMutation,
  useDeleteApiResponseAnalysisReportsByIdMutation,
  useGetApiResponseAnalysisReportsByIdQuery,
  useGetApiScanAnalysesByScansQuery,
  useGetApiScanAnalysesByIdStepsQuery,
  usePostApiScanAnalysesMutation,
  useGetApiScanAnalysesQuery,
  usePutApiScanAnalysesByIdMutation,
  useDeleteApiScanAnalysesByIdMutation,
  useGetApiScanAnalysesByIdQuery,
  usePostApiScanAnalysisStepsByIdTasksMutation,
  useGetApiScanAnalysisStepsByIdTasksQuery,
  usePostApiScanAnalysisStepsByIdTransitionMutation,
  useGetApiScanAnalysisStepsByIdArtifactUrlsQuery,
  usePostApiScanAnalysisStepsByIdManuallyMarkCompleteMutation,
  usePostApiScanAnalysisStepsMutation,
  useGetApiScanAnalysisStepsQuery,
  usePutApiScanAnalysisStepsByIdMutation,
  useDeleteApiScanAnalysisStepsByIdMutation,
  useGetApiScanAnalysisStepsByIdQuery,
  useGetApiScanAnalysisViewModelsByIdQuery,
  useGetApiScanModalitiesByIdQuery,
  useGetApiScanModalitiesQuery,
  usePostApiScanpackagesProcessRequestMutation,
  usePostApiScanpackagesValidationMutation,
  usePostApiScanpackagesValidateManifestFileMutation,
  useGetApiScanProcessingOutputsTemporaryUriByRelativeFilePathQuery,
  useGetApiScanProcessingOutputsJsonContentByRelativePathQuery,
  useGetApiScanProcessingTaskArgumentsByIdQuery,
  useGetApiScanProcessingTaskArgumentsQuery,
  usePostApiScanProcessingTasksByIdRequestsStartMutation,
  useGetApiScanProcessingTasksByIdDtoQuery,
  useGetApiScanProcessingTasksByIdStatusesQuery,
  usePostApiScanProcessingTasksByIdSingleStepOutputArtifactFileMutation,
  usePostApiScanProcessingTasksByIdSingleStepOutputArtifactTextMutation,
  useGetApiScanProcessingTasksByIdSingleScanInputSourceUrlQuery,
  usePostApiScanProcessingTasksMutation,
  useGetApiScanProcessingTasksQuery,
  usePutApiScanProcessingTasksByIdMutation,
  useDeleteApiScanProcessingTasksByIdMutation,
  useGetApiScanProcessingTasksByIdQuery,
  useGetApiScanProcessingTaskStatusesByIdQuery,
  useGetApiScanProcessingTaskStatusesQuery,
  useGetApiScanProcessingTaskStatusTypesByIdQuery,
  useGetApiScanProcessingTaskStatusTypesQuery,
  useGetApiScansByIdExamQuery,
  usePostApiScansMutation,
  useGetApiScansQuery,
  usePutApiScansByIdMutation,
  useDeleteApiScansByIdMutation,
  useGetApiScansByIdQuery,
  useGetApiSexesByIdQuery,
  useGetApiSexesQuery,
  useGetApiSingleScanApplicationsQuery,
  useGetApiStudiesByIdExamsQuery,
  usePostApiStudiesByIdPatientsAndPatientIdMutation,
  useGetApiStudiesByIdPatientsQuery,
  useGetApiStudiesByIdTimepointsQuery,
  useGetApiStudiesByIdScanProcessingTaskStatusesQuery,
  useGetApiStudiesByIdScanAnalysisViewModelsQuery,
  useGetApiStudiesByIdScanAnalysisViewModelsByIdsQuery,
  useGetApiStudiesByIdInterscanAnalysisViewModelsQuery,
  useGetApiStudiesByIdCompoundInterscanAnalysisViewModelsQuery,
  usePostApiStudiesMutation,
  useGetApiStudiesQuery,
  usePutApiStudiesByIdMutation,
  useDeleteApiStudiesByIdMutation,
  useGetApiStudiesByIdQuery,
  useGetApiStudyStatusesByIdQuery,
  useGetApiStudyStatusesQuery,
  usePostApiTimepointsMutation,
  useGetApiTimepointsQuery,
  usePutApiTimepointsByIdMutation,
  useDeleteApiTimepointsByIdMutation,
  useGetApiTimepointsByIdQuery,
  useGetApiTracersByIdQuery,
  useGetApiTracersQuery,
} = injectedRtkApi;
