import * as React from "react";
import {
  Typography,
  Grid,
  Paper,
  Divider,
  Button,
  Box,
  CircularProgress,
  List,
  ListItem,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import WarningIcon from '@mui/icons-material/Warning';
import { InterscanApplication, Study, useGetApiInterscanApplicationsQuery, useGetApiSingleScanApplicationsQuery } from "../../app/api/aiq-api";
import { enhancedApi } from "../../app/api/enhancedApi";
import { ApplicationSelectMenu } from "./ApplicationSelectMenu";
import InterscanCreateSingleScanTable from "./InterscanCreateSingleScanTable";
import { useDispatch, useSelector } from "react-redux";
import { SelectedStudy } from "../../app/selectors/studySelectors";
import { ClientSideSingleScanAnalysisViewModel } from "../../redux/services/view-models/ClientSideSingleScanAnalysisViewModel";
import {
  SelectedStudySingleScanAnalysisVMsSelector,
  SelectedStudyScanAnalysesTotalCountSelector,
  SelectedStudyScanAnalysesLoadingSelector,
} from "../../app/selectors/singleScanAnalysisSelectors";
import { ApplicationState } from "../../redux/store/ConfigureStore";
import {
  setSingleScanAnalysisPatientFilter,
  setSingleScanAnalysisPageNumber,
} from "../../app/slices/scanAnalysisSlice";
import { FilterMenu } from "./FilterMenu";
import {
  AllSingleScanApplicationsSelector,
  AllInterscanApplicationsSelector,
} from "../../app/selectors/applicationSelectors";
import produce from "immer";
import { InterscanSingleScanGroupTable } from "./InterscanCreateSingleScanGroupTable";
import { toast } from "react-toastify";

interface IInterscanCreatePopupProps {
  onCancel: () => void;
  selectedStudyId: string;
}

/**
 * This is a connected component that displays a list of Platform Studies
 */
export const InterscanCreatePopup: React.FunctionComponent<IInterscanCreatePopupProps> = (props) => {
  const interscanApplications = useSelector((state: ApplicationState) =>AllInterscanApplicationsSelector(state));
  const singleScanApplications = useSelector((state: ApplicationState) =>AllSingleScanApplicationsSelector(state));
  const [activeSingleScans, setActiveSingleScanIds] = React.useState<Array<ClientSideSingleScanAnalysisViewModel>>([]);
  const [selectedSingleScans, setSelectedSingleScanIds] = React.useState<Map<number, Array<ClientSideSingleScanAnalysisViewModel>>>(new Map());
  const [selectedApplicationId, setSelectedApplicationId] = React.useState<string | undefined | null>(interscanApplications?.find((x) => x != null && !x.deprecated && !x.disabled)?.systemId);
  const [selectedApplication, setSelectedApplication] = React.useState<InterscanApplication | undefined>(interscanApplications?.find((s) => s.systemId == selectedApplicationId));
  const [doCreateInterscanAnalysis] = enhancedApi.endpoints.postApiInterscanAnalysesCreateAndInitializeUngrouped.useMutation();
  const [doCreateGroupedInterscanAnalysis] = enhancedApi.endpoints.postApiInterscanAnalysesCreateAndInitializeGrouped.useMutation();
  const singleScanAnalysisVMs: Array<ClientSideSingleScanAnalysisViewModel> = useSelector((state: ApplicationState) => SelectedStudySingleScanAnalysisVMsSelector(state));
  const singleScanAnalysisVMsLoading = useSelector((state: ApplicationState) => SelectedStudyScanAnalysesLoadingSelector(state));
  const selectedStudy: Study | undefined = useSelector((state: ApplicationState) => SelectedStudy(state));
  const [loadSingleScanAnalysisVMsQuery, loadMoreSingleScanAnalysisVMsResult] = enhancedApi.endpoints.getApiStudiesByIdScanAnalysisViewModels.useLazyQuery();
  const [loadFilteredSingleScanAnalysisVMs, filterSingleScanAnalysisVMsResult] = enhancedApi.endpoints.getApiStudiesByIdScanAnalysisViewModels.useLazyQuery();
  const [autoSortByDate, setautoSortByDate] = React.useState<boolean>(true);
  useGetApiInterscanApplicationsQuery();
  useGetApiSingleScanApplicationsQuery();

  const dispatch = useDispatch();

  const [aggregateSingleScanVMs, setAggregateSingleScanVMs] = React.useState<Array<ClientSideSingleScanAnalysisViewModel>>([]);

  // Pagination and Filtering State
  const singleScanPageNumber: number = useSelector((state: ApplicationState) => state.scanAnalyses.singleScanAnalysisPageNumber);
  const singleScanPageSize: number = useSelector((state: ApplicationState) => state.scanAnalyses.singleScanAnalysisPageSize);
  const singleScanPatientFilter: string | undefined = useSelector((state: ApplicationState) => state.scanAnalyses.singleScanAnalysisPatientFilter);
  const singleScanTotalCount = useSelector(SelectedStudyScanAnalysesTotalCountSelector);

  React.useEffect(() => {
    dispatch(setSingleScanAnalysisPageNumber(0));
    loadMoreSingleScanAnalysisVMs();
  }, []);

  React.useEffect(() => {
    setSelectedApplication(interscanApplications?.find((s) => s.systemId == selectedApplicationId));
  }, [selectedApplicationId, interscanApplications]);

  React.useEffect(() => {
    if (loadMoreSingleScanAnalysisVMsResult.isSuccess && !loadMoreSingleScanAnalysisVMsResult.isFetching) {
      var aggregateSingleScanVMsCopy = [...aggregateSingleScanVMs];
      singleScanAnalysisVMs.forEach((savm) => {
        if (!aggregateSingleScanVMsCopy.find((x) => x.scanAnalysis.id === savm.scanAnalysis.id)) {
          aggregateSingleScanVMsCopy.push(savm);
        }
      });
      setAggregateSingleScanVMs(aggregateSingleScanVMsCopy);
    }
  }, [loadMoreSingleScanAnalysisVMsResult]);

  React.useEffect(() => {
    if (filterSingleScanAnalysisVMsResult.isSuccess && !filterSingleScanAnalysisVMsResult.isFetching) {
      setAggregateSingleScanVMs(singleScanAnalysisVMs);
    }
    if (filterSingleScanAnalysisVMsResult.isFetching) {
      dispatch(setSingleScanAnalysisPageNumber(0));
      setAggregateSingleScanVMs([]);
    }
  }, [filterSingleScanAnalysisVMsResult]);

  React.useEffect(() => {
    loadMoreSingleScanAnalysisVMs();
  }, [singleScanPageNumber]);

  React.useEffect(() => {
    filterSingleScanAnalysisVMs();
  }, [singleScanPatientFilter]);

  const handleApplicationSelectionChange = (selectedApplicationId: string | undefined) => {
    setSelectedApplicationId(selectedApplicationId);
    setActiveSingleScanIds([]);
    setSelectedSingleScanIds(new Map([]));
  };

  const getTruncatedId = (id: string): string => {
    return `${id.substring(0, 6)}...`;    
  };

  const getNumberOfPages = (): number => {
    return Math.ceil(singleScanTotalCount / singleScanPageSize);
  };

  const onSingleScanSelectionChange = (partialScanAnalysisId: string[]) => {
    var selectedSingleScanAnalyses = aggregateSingleScanVMs?.filter((x) =>
      partialScanAnalysisId.includes(getTruncatedId(x.scanAnalysis.id!))
    );
    setActiveSingleScanIds(selectedSingleScanAnalyses);
  };

  const handleCreateClicked = () => {
    if (selectedApplicationIsGrouped()) {
      var idGroups = [...selectedSingleScans.values()].map((group) =>
        group.map((scanAnalysisVM) => scanAnalysisVM.scanAnalysis.id!)
      );
      doCreateGroupedInterscanAnalysis({
        groupedInterscanAnalysisCreateAndInitializeRequest: {
          interscanApplicationSystemId: selectedApplicationId,
          singleScanAnalysisIdGroups: idGroups,
        },
      });
    } else {
      var ids = [
        selectedSingleScans.get(0)![0].scanAnalysis.id!,
        selectedSingleScans.get(1)![0].scanAnalysis.id!,
      ];
      doCreateInterscanAnalysis({
        ungroupedInterscanAnalysisCreateAndInitializeRequest: {
          interscanApplicationSystemId: selectedApplicationId,
          singleScanAnalysisIds: ids,
        },
      });
    }
    props.onCancel();
  };

  const loadMoreSingleScanAnalysisVMs = () => {
    if (selectedStudy) {
      loadSingleScanAnalysisVMsQuery({
        id: selectedStudy.id!,
        pageNumber: singleScanPageNumber,
        pageSize: singleScanPageSize,
        patientIdentifierSearchPattern: singleScanPatientFilter,
      });
    }
  };

  const filterSingleScanAnalysisVMs = () => {
    if (selectedStudy) {
      loadFilteredSingleScanAnalysisVMs({
        id: selectedStudy.id!,
        pageNumber: singleScanPageNumber,
        pageSize: singleScanPageSize,
        patientIdentifierSearchPattern: singleScanPatientFilter,
      });
    }
  };

  const handleAddToScanGroup = (groupIndex: number) => {
    let updatedSelectedSingleScanIds = produce(selectedSingleScans, (newSet) => {
      let alreadySelected: Array<ClientSideSingleScanAnalysisViewModel> =
        selectedSingleScans.get(groupIndex) ?? [];
      let newlySelectedSingleScans: Array<ClientSideSingleScanAnalysisViewModel> =
        activeSingleScans.concat(alreadySelected);
      let dedupedList = [...new Set<ClientSideSingleScanAnalysisViewModel>(newlySelectedSingleScans)];
      newSet.set(groupIndex, dedupedList);
    });
    setSelectedSingleScanIds(updatedSelectedSingleScanIds);
    setActiveSingleScanIds([]);
  };

  const handleAutoAddToScanGroup = () => {
    const allCurrentlySelected: ClientSideSingleScanAnalysisViewModel[] = Array.from(selectedSingleScans.values()).flatMap(scan => scan);
    const dedupedCombinedSelectedAndActive = [... new Set<ClientSideSingleScanAnalysisViewModel>(allCurrentlySelected.concat(activeSingleScans))];

    const examsByDate = dedupedCombinedSelectedAndActive.reduce((accumulator, current) => {
      const date = current.exam.examDate!;
      if (!accumulator[date]) { accumulator[date] = []; }
      accumulator[date].push(current);
      return accumulator;
    }, {} as Record<string, ClientSideSingleScanAnalysisViewModel[]>);

    const sortedExamsByDate: Record<string, ClientSideSingleScanAnalysisViewModel[]> = Object.entries(examsByDate)
      .sort(([a], [b]) => new Date(a).getTime() - new Date(b).getTime())
      .reduce((accumulator, [key, value]) => { accumulator[key] = value; return accumulator;
    }, {} as Record<string, ClientSideSingleScanAnalysisViewModel[]>);

    if(Object.keys(sortedExamsByDate).length > (selectedApplication?.singleScanGroupSizes!.length ?? 0)) {
      toast(
        `Scans with ${Object.keys(sortedExamsByDate).length} dates were selected, but this application only supports ${(selectedApplication?.singleScanGroupSizes!.length ?? 0)}`,
        {position: toast.POSITION.TOP_CENTER}
      );
      setActiveSingleScanIds([]);
      return;
    }

    var updatedList = new Map<number, Array<ClientSideSingleScanAnalysisViewModel>>()
    Object.keys(sortedExamsByDate).forEach((key, index) => {
      updatedList.set(index, sortedExamsByDate[key]);
    })

    setSelectedSingleScanIds(updatedList);

    setActiveSingleScanIds([]);
  };

  const handleClearScanGroup = (groupIndex: number) => {
    let updatedSelectedSingleScanIds = produce(selectedSingleScans, (newSet) => {
      newSet.set(groupIndex, []);
    });

    setSelectedSingleScanIds(updatedSelectedSingleScanIds);
  };

  const handleClearAllScanGroups = () => {
    setSelectedSingleScanIds(new Map<number, Array<ClientSideSingleScanAnalysisViewModel>>()) 
  }

  const getSingleScanApplicaitionBySystemId = (systemId: string): InterscanApplication | undefined => {
    return singleScanApplications?.find((x) => x.systemId == systemId);
  };

  const scanViewModelsToTableRows = (
    scanVms: Array<ClientSideSingleScanAnalysisViewModel> | undefined | null
  ): Array<Array<string>> => {
    if (!scanVms) return [[]];
    return scanVms.map((scanVm) => {
      return [
        scanVm.patient!.patientIdentifier!,
        scanVm.timepoint?.timepointName ?? "UNKNOWN",
        scanVm.exam?.examDate ?? "UNKNOWN",
        scanVm.anatomicalScanViewModel.scanSeries!,
        scanVm.applicationTemplate.displayName!,
        scanVm.exam?.createdDate ?? "UNKNOWN",
        getTruncatedId(scanVm.scanAnalysis?.id!) ?? "UNKNOWN",
      ];
    });
  };

  const scanViewModelsToShortTableRows = (
    scanVms: Array<ClientSideSingleScanAnalysisViewModel> | undefined | null
  ): Array<Array<string>> => {
    if (!scanVms) return [];
    return scanVms.map((scanVm) => {
      return [
        scanVm.patient!.patientIdentifier!,
        scanVm.timepoint?.timepointName ?? "UNKNOWN",
        scanVm.exam?.examDate ?? "UNKNOWN",
        scanVm.anatomicalScanViewModel.scanSeries!,
        scanVm.applicationTemplate.displayName!,
        getTruncatedId(scanVm.scanAnalysis?.id!) ?? "UNKNOWN",
      ];
    });
  };

  const filteredScansForList = () => {
    let result =  scanViewModelsToTableRows(
      aggregateSingleScanVMs.filter(
        (x) =>
          selectedApplication?.validSingleScanApplicationInputs?.includes(
            x.scanAnalysis?.applicationSystemId!
          ) && x.singleScanProcessingIsComplete
      )
    );
    return result;
  };

  const getMaxGroupSize = (groupIndex: number): number => {
    return selectedApplicationIsGrouped() ? selectedApplication!.singleScanGroupSizes![groupIndex] : 1;
  };

  const getNumberOfPopulatedSingleScanGroups = (): number => {
    var selectedSingleScanCollections: ClientSideSingleScanAnalysisViewModel[][] = [...selectedSingleScans.values()];
    return selectedSingleScanCollections.filter(
      (x, i) => x != undefined && x.length > 0 && x.length <= getMaxGroupSize(i)
    ).length;
  };

  const selectedApplicationIsGrouped = (): boolean => {
    return selectedApplication !== undefined && (selectedApplication?.singleScanGroupSizes?.filter(x => x > 1).length ?? 0) > 0;
  };

  const getWarningsForScanGroup = (
    scanGroup: Array<ClientSideSingleScanAnalysisViewModel> | undefined | null
  ): Array<string> => {
    let warnings = new Array<string>();

    if (scanGroup == undefined || scanGroup == undefined) return warnings;

    const seriesIds = scanGroup.map((x) => x.anatomicalScanViewModel.scanSeries!);
    if (new Set(seriesIds).size > 1) {
      warnings.push("Group may contain mixed scan series");
    }

    const patientIds = scanGroup.map((x) => x.patient.id!);
    if (new Set(patientIds).size > 1) {
      warnings.push("Group may contain mixed patients");
    }
    return warnings;
  };

  const getWarningsForAnalysis = (): Array<string> => {
    let warnings = new Array<string>();

    // Get a flat list of all the selectedSingleScans
    var selectedSingleScanCollections: ClientSideSingleScanAnalysisViewModel[][] = [...selectedSingleScans.values()];
    var allSelectedPatientIds = selectedSingleScanCollections.map(x => x.map(y => y.patient.id!)).flat();

    //dedupe the list of patient ids
    allSelectedPatientIds = [...new Set(allSelectedPatientIds)];
    
    if(allSelectedPatientIds.length > 1) {
      warnings.push("Selected scans may contain mixed patients")
    }

    return warnings;
  }

  const getMaxSelectable = (): number => {
    if(selectedApplication == null)  return 0;
    const selectedSingleScanCollections: ClientSideSingleScanAnalysisViewModel[][] = [...selectedSingleScans.values()];
    const totalSelected = selectedSingleScanCollections!.reduce((acc, curr) => acc + curr.length,0);
    const totalSlots =  selectedApplication.singleScanGroupSizes!.reduce((acc, curr) => acc + curr,0);

    if(autoSortByDate) {
      return totalSlots - totalSelected;
    } else {
      var maxSlots = 0;
      selectedApplication.singleScanGroupSizes!.forEach((groupSize, index) => {
        let usedSlots = (selectedSingleScanCollections[index]?.length ?? 0)
        let emptySlots: number = groupSize - usedSlots;
        maxSlots = Math.max(maxSlots, emptySlots);
      })
      return maxSlots;
    }
  }

  return (
    <React.Fragment>
      <Paper sx={{ width: "100%", height: "100%", padding: "20px", display: "block" }}>
        <Typography variant="h4" sx={{ mr: 1 }} gutterBottom>
          Create Interscan Analysis
        </Typography>
        <Divider />

        <Box sx={{ width: 800 }}>
          <ApplicationSelectMenu
            applications={interscanApplications ?? []}
            selectedApplicationId={selectedApplicationId ?? null}
            nullSelectLabel="Use Application from Manifest"
            onSelectionChanged={(applicationId: string | undefined) =>
              handleApplicationSelectionChange(applicationId)
            }
          />
        </Box>

        <Box sx={{ mt: 1, height: "calc(100% - 190px)" }}>
          {selectedApplication && filteredScansForList().length > 0 && (
            <Box sx={{ height: "100%" }}>
              <Box sx={{ width: 600, height: 55 }}>
                <FilterMenu
                  filterValue={singleScanPatientFilter}
                  onFilterChange={(updatedValue: string | undefined) =>
                    dispatch(setSingleScanAnalysisPatientFilter(updatedValue))
                  }
                />
              </Box>
              <Box sx={{ display: "flex", height: "calc(100% - 55px)" }}>
                <InterscanCreateSingleScanTable
                  keyRowIndex={6}
                  maxSelect={getMaxSelectable()}
                  onSelectionChange={(data) => onSingleScanSelectionChange(data)}
                  selected={activeSingleScans.map((scan) => getTruncatedId(scan.scanAnalysis.id!))}
                  assigned={Array.from(selectedSingleScans.values())
                    .flatMap((scan) => scan)
                    .map((scan) => getTruncatedId(scan.scanAnalysis.id!))}
                  headCells={[
                    { id: "patiendIdentifier", numeric: false, date: false, label: "Patient Identifier", width: 120 },
                    { id: "timepointName", numeric: false, date: false, label: "Timepoint", width: 110 },
                    { id: "examDate", numeric: false, date: true, label: "Exam Date", width: 95 },
                    { id: "series", numeric: false, date: false, label: "Series", width: 50 },
                    { id: "singleScanApplication", numeric: false, date: false, label: "Application", width: 100 },
                    { id: "createdDate", numeric: false, date: true, label: "Date Created", width: 105},
                    { id: "id", numeric: false, disablePadding: true, asTooltip: true, label: "Id", width: 50 },
                  ]}
                  data={filteredScansForList()}
                />
                {autoSortByDate && (
                  <Grid
                    container
                    direction="column"
                    alignItems="center"
                    justifyContent="center"
                    sx={{ width: 56, ml: 1, mr: 1 }}
                  >
                    <Button
                      data-cy="AddToSingleScanGroup"
                      sx={{ my: 0.5, minWidth: 55 }}
                      variant="outlined"
                      size="small"
                      onClick={() => handleAutoAddToScanGroup()}
                      disabled={
                        activeSingleScans.length === 0 ||
                        activeSingleScans.length + (selectedSingleScans.get(0) ?? []).length >
                          getMaxGroupSize(0) + getMaxGroupSize(1)
                      }
                      aria-label="move selected right"
                    >
                      &gt;
                    </Button>
                    <Button
                      data-cy="ClearSingleScanGroup"
                      sx={{ my: 0.5, minWidth: 55, fontSize: ".65rem" }}
                      variant="outlined"
                      size="small"
                      onClick={() => {
                        handleClearAllScanGroups();
                      }}
                      disabled={
                        (selectedSingleScans.get(0) == undefined &&
                          selectedSingleScans.get(1) == undefined) ||
                        (selectedSingleScans.get(0)?.length && selectedSingleScans.get(1)?.length) === 0
                      }
                      aria-label="move all left"
                    >
                      clear
                    </Button>
                  </Grid>
                )}
                <Box sx={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {[...Array(selectedApplication.numberOfInputSingleScanAnalyses ?? 2)].map(
                      (x, groupIndex) => (
                        <Box
                          key={groupIndex}
                          sx={{ display: "flex", flexDirection: "row", alignItems: "center", mb: 2 }}
                        >
                          {!autoSortByDate && (
                            <Grid container direction="column" alignItems="center" sx={{ ml: 1, mr: 1 }}>
                              <Button
                                data-cy="AddToSingleScanGroup"
                                sx={{ my: 0.5, minWidth: 55 }}
                                variant="outlined"
                                size="small"
                                onClick={() => handleAddToScanGroup(groupIndex)}
                                disabled={
                                  activeSingleScans.length === 0 ||
                                  activeSingleScans.length +
                                    (selectedSingleScans.get(groupIndex) ?? []).length >
                                    getMaxGroupSize(groupIndex)
                                }
                                aria-label="move selected right"
                              >
                                &gt;
                              </Button>
                              <Button
                                data-cy="ClearSingleScanGroup"
                                sx={{ my: 0.5, minWidth: 55, fontSize: ".65rem" }}
                                variant="outlined"
                                size="small"
                                onClick={() => handleClearScanGroup(groupIndex)}
                                disabled={
                                  selectedSingleScans.get(groupIndex) == undefined ||
                                  selectedSingleScans.get(groupIndex)?.length === 0
                                }
                                aria-label="move all left"
                              >
                                clear
                              </Button>
                            </Grid>
                          )}
                          <Box>
                            <Typography>
                              {selectedApplication?.singleScanGroupDisplayNames?.[groupIndex] ?? `Scan ${groupIndex + 1}`}
                            </Typography>
                            <Typography
                              variant="subtitle2"
                              sx={{ fontSize: ".65rem" }}
                              color="secondary.dark"
                            >
                              {(selectedSingleScans.get(groupIndex) ?? []).length} of{" "}
                              {getMaxGroupSize(groupIndex) > 1 ? `1 to ${getMaxGroupSize(groupIndex)}` : getMaxGroupSize(groupIndex)} scans selected
                            </Typography>
                            <InterscanSingleScanGroupTable
                              keyRowIndex={5}
                              data-cy="InterscanSingleScanGroupTable"
                              maxCapacity={selectedApplication.singleScanGroupSizes![groupIndex]!}
                              headCells={[
                                { id: "patiendIdentifier", numeric: false, date: false, label: "Patient Identifier", width: 110 },
                                { id: "timepointName", numeric: false, date: false, label: "Timepoint", width: 110 },
                                { id: "examDate", numeric: false, date: true, label: "Exam Date", width: 80 },
                                { id: "series", numeric: false, date: false, label: "Series", width: 40 },
                                { id: "singleScanApplication", numeric: false, date: false, label: "Application", width: 100 },
                                { id: "id", numeric: false, disablePadding: true, label: "Id", width: 50 },
                              ]}
                              warnings={getWarningsForScanGroup(selectedSingleScans.get(groupIndex))}
                              data={scanViewModelsToShortTableRows(selectedSingleScans.get(groupIndex))}
                            />
                          </Box>
                        </Box>
                      )
                    )}
                  </Box>
                  <Box sx={{ width: "100%", ml: autoSortByDate ? "1px": "73px"  }}>
                    <FormControlLabel
                      sx={{ width: "100%", height: "100%" }}
                      control={
                        <Checkbox
                          data-cy="AutosortCheckbox"
                          size="small"
                          checked={autoSortByDate}
                          onChange={(event) => {
                            setautoSortByDate(event.target.checked);
                            setActiveSingleScanIds([]);
                            setSelectedSingleScanIds(
                              new Map<number, Array<ClientSideSingleScanAnalysisViewModel>>()
                            );
                          }}
                          name="autoSortByDate"
                        />
                      }
                      label={
                        <Typography variant="body2">Automatically group and order by exam date</Typography>
                      }
                    />
                    {getWarningsForAnalysis().map((warning, i) => (
                      <Box 
                        key={i}
                        data-cy="analysis-warnings-container"
                        sx={{ py: .15, color: "orange", ml: "-5px"}}
                      >
                      <Typography variant="subtitle2">
                        <WarningIcon fontSize="inherit" sx={{mx: 1}}/>
                        {warning}
                      </Typography>
                      </Box>
                    ))}                    
                  </Box>
                </Box>
              </Box>
            </Box>
          )}
          {!singleScanAnalysisVMsLoading &&
            selectedApplication &&
            singleScanApplications &&
            selectedStudy &&
            filteredScansForList().length == 0 && (
              <React.Fragment>
                <Typography variant="subtitle1" sx={{ padding: 3 }}>
                  Study <strong>{selectedStudy.studyName}</strong> has no completed single scan analyses that
                  are compatible with Interscan Application <strong>{selectedApplication.displayName}</strong>
                  .
                </Typography>
                <Typography variant="subtitle1" sx={{ padding: 3 }}>
                  The following Single Scan Applications are compatible with{" "}
                  <strong>{selectedApplication.displayName}</strong>:{" "}
                  <List dense={true} sx={{ m: 2 }}>
                    {selectedApplication.validSingleScanApplicationInputs?.map((x) => (
                      <ListItem
                        sx={{ fontSize: ".75rem", display: "list-item", listStyleType: "disc", pl: 0 }}
                      >
                        {singleScanApplications.find((s) => s.systemId == x)?.displayName}
                      </ListItem>
                    ))}
                  </List>
                </Typography>
              </React.Fragment>
            )}
          {singleScanAnalysisVMsLoading && (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                p: 3,
                height: "100%",
              }}
            >
              <CircularProgress color="inherit" />
            </Box>
          )}
          {singleScanAnalysisVMs.length > 0 && singleScanPageNumber + 1 < getNumberOfPages() && (
            <Box sx={{ display: "flex", justifyContent: "center", width: 600, height: 50 }}>
              {!singleScanAnalysisVMsLoading && (
                <Button
                  data-cy="LoadMoreButton"
                  onClick={() => dispatch(setSingleScanAnalysisPageNumber(singleScanPageNumber + 1))}
                >
                  Load more...
                </Button>
              )}
            </Box>
          )}
        </Box>

        <Grid
          container
          spacing={1}
          sx={{ mt: 1, height: 60 }}
          justifyContent="space-between"
          alignItems="flex-end"
        >
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexFlow: "row wrap",
              "& Button": {
                marginRight: "10px",
              },
            }}
          >
            <Button
              data-cy="createInterscanAcceptButton"
              variant="contained"
              color="primary"
              disabled={
                getNumberOfPopulatedSingleScanGroups() != selectedApplication?.numberOfInputSingleScanAnalyses
              }
              onClick={handleCreateClicked}
            >
              Create
            </Button>
            <Button
              data-cy="createInterscanCancelButton"
              variant="contained"
              color="secondary"
              onClick={props.onCancel}
            >
              Cancel
            </Button>
            <Typography variant="h6">
              {getNumberOfPopulatedSingleScanGroups()} of{" "}
              {selectedApplication?.numberOfInputSingleScanAnalyses ?? 0} required single scan{" "}
              {selectedApplicationIsGrouped() ? "groups populated" : "analyses selected"}
            </Typography>

          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
};
